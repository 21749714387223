/* eslint-disable import/no-cycle */
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { routeChangeComplete } from '@hooks/useResetState';

import { setHeader } from '@store/api/request/setHeader';
import { Any, HydrateAction } from '@store/global/type';

import {
  autoLogin,
  changeEmailRequest,
  changePassword,
  checkVerificationCode,
  createPassword,
  fetchToken,
  getVerificationInfo,
  login,
  loginViaOAuth,
  logout,
  resetPassword,
  saveLoginPayload,
  sendVerificationCode,
  setToken,
} from './thunk';
import { AuthInitialState } from './type';
import { SET_LOADING } from './utils';

const initialState: AuthInitialState = {
  token: '0',
  isLoading: false,
  errors: [],
  isSuccessfulLogin: false,
  isRequestedChangeEmail: false,
  loading: '',
  resetPassword: {
    activeStep: 0,
  },
  queryParams: {},
  verification: {
    errors: [],
    validationCheckPayload: {},
    errorCode: [],
    cancelationInfo: {},
  },
  loginReasonInfo: {
    seenLoginReason: false,
    loginReasonType: '',
    loginReasonText: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    resetOAuthErrors(state) {
      state.errors = [];
      state.isSuccessfulLogin = false;
    },
    resetValidation(state) {
      state.verification.errorCode = [];
    },
    setSuccessfulyLogin(state) {
      state.isSuccessfulLogin = true;
    },
    setLoginReasonInfo(state, action) {
      state.loginReasonInfo.loginReasonText = action.payload.text;
      state.loginReasonInfo.loginReasonType = action.payload.type ?? 'info';
      state.loginReasonInfo.seenLoginReason = false;
    },
    setSeenLoginReason(state) {
      state.loginReasonInfo.seenLoginReason = true;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'auth'>) => {
        if (!isEqual(state, action.payload[authSlice.name])) {
          Object.assign(state, action.payload.auth);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      state.errors = [];
      state.isRequestedChangeEmail = false;
    });

    builder.addCase(fetchToken.fulfilled, (state, action) => {
      state.token = action.payload;
    });

    builder.addCase(fetchToken.rejected, (state) => {
      state.token = '';
    });

    builder.addCase(setToken.fulfilled, (state, action) => {
      state.token = action.payload;
      if (action.payload) {
        setHeader('Authorization', action.payload);
      }
    });

    builder.addCase(login.pending, (state) => {
      state.errors = [];
      state.loading = 'direct';
    });

    builder.addCase(login.fulfilled, (state) => {
      state.isSuccessfulLogin = true;
      state.loading = '';
      state.isLoading = false;
    });

    builder.addCase(login.rejected, (state, action) => {
      state.errors = action.payload;
      state.verification.errors = action.payload;
      state.loading = '';
      state.isLoading = false;
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      state.token = '';
      state.isSuccessfulLogin = false;
      state.isLoading = false;
      state.queryParams = action.payload;
      state.verification.cancelationInfo = [];
    });

    builder.addCase(loginViaOAuth.pending, (state) => {
      state.errors = [];
      state.isLoading = true;
    });

    builder.addCase(loginViaOAuth.fulfilled, (state, action) => {
      if (action.payload) {
        state.isSuccessfulLogin = true;
      }
      state.loading = '';
      state.isLoading = false;
    });

    builder.addCase(loginViaOAuth.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading = '';
      state.isLoading = false;
    });

    builder.addCase(autoLogin.fulfilled, (state) => {
      state.isLoading = false;
      state.loading = '';
      state.isSuccessfulLogin = true;
    });

    builder.addCase(SET_LOADING, (state, action: Any) => {
      state.loading = action.payload;
    });

    builder.addCase(changePassword.pending, (state) => {
      state.errors = [];
      state.isLoading = true;
    });

    builder.addCase(changePassword.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(changePassword.rejected, (state, action) => {
      state.errors = action.payload;
      state.isLoading = false;
    });

    builder.addCase(createPassword.pending, (state) => {
      state.errors = [];
      state.isLoading = true;
    });

    builder.addCase(createPassword.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(createPassword.rejected, (state, action) => {
      state.errors = action.payload;
      state.isLoading = false;
    });

    builder.addCase(changeEmailRequest.pending, (state) => {
      state.errors = [];
      state.isLoading = true;
    });

    builder.addCase(changeEmailRequest.fulfilled, (state) => {
      state.isLoading = false;
      state.isRequestedChangeEmail = true;
    });

    builder.addCase(changeEmailRequest.rejected, (state, action) => {
      state.errors = action.payload;
      state.isLoading = false;
    });

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.resetPassword.activeStep = 1;
      state.isLoading = false;
      state.errors = [];
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.errors = action.payload;
      state.isLoading = false;
    });

    builder.addCase(sendVerificationCode.pending, (state) => {
      state.verification.errors = initialState.verification.errors;
    });

    builder.addCase(sendVerificationCode.fulfilled, (state) => {
      state.verification.errors = [];
    });

    builder.addCase(sendVerificationCode.rejected, (state, action) => {
      state.verification.errors = action.payload;
    });

    builder.addCase(saveLoginPayload.fulfilled, (state, action) => {
      state.verification.validationCheckPayload = action.payload;
    });

    builder.addCase(checkVerificationCode.pending, (state) => {
      state.verification.errorCode = [];
      state.isLoading = true;
    });

    builder.addCase(checkVerificationCode.fulfilled, (state) => {
      state.isSuccessfulLogin = true;
      state.loading = '';
      state.isLoading = false;
      state.verification.errorCode = [];
    });

    builder.addCase(checkVerificationCode.rejected, (state, action) => {
      state.verification.errorCode = action.payload;
      state.isLoading = false;
    });

    builder.addCase(getVerificationInfo.fulfilled, (state, action) => {
      state.verification.cancelationInfo = action.payload;
    });

    builder.addCase(getVerificationInfo.rejected, (state) => {
      state.verification.cancelationInfo = [];
    });
  },
});

export const {
  setLoginReasonInfo,
  setSeenLoginReason,
  resetOAuthErrors,
  resetValidation,
  setSuccessfulyLogin,
} = authSlice.actions;

export default authSlice;
