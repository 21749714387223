/* eslint-disable consistent-return */
import isEmpty from 'lodash/isEmpty';

import { createAppAsyncThunk } from '@store/global/utils';
import { toggleModal } from '@store/modal/slice';
import { PollAPI } from '@store/poll/api';

import { PollPayload, PollVotePayload } from './type';

export const fetchPollData = createAppAsyncThunk(
  'poll/fetchPollData',
  async (payload: PollPayload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const { data } = await PollAPI.fetchPollData(thunkAPI)(payload);

      if (!isEmpty(data.results)) {
        return data.results;
      }
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors);
    }
  }
);

export const vote = createAppAsyncThunk(
  'poll/vote',
  async (payload: PollVotePayload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const { data } = await PollAPI.vote(thunkAPI)(payload);

    const { pageType } = getState().meta;
    await dispatch(fetchPollData({ pageType }));

    return data.results;
  }
);

export const showPollResult = createAppAsyncThunk(
  'poll/showPollResult',
  async (_, { getState, dispatch }) => {
    if (getState().auth.token) {
      dispatch(toggleModal('[POLL]'));
    }
  }
);

export const openPollModal = createAppAsyncThunk(
  'poll/openPollModal',
  async (payload: PollPayload, thunkAPI) => {
    const { dispatch } = thunkAPI;

    dispatch(fetchPollData(payload)).then((response) => {
      const { meta, payload: pollData } = response;
      if (meta.requestStatus === 'fulfilled' && pollData?.isVoted) {
        dispatch(showPollResult());
      } else {
        dispatch(toggleModal('[POLL]'));
      }
    });
  }
);
