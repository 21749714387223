import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { HydrateAction } from '@store/global/type';
import {
  checkKpIzlogIdent,
  fetchKpIzlog,
  fetchKpIzlogStat,
} from '@store/kpIzlog/thunk';

import { KpIzlogInitialState, KpIzlogStatResults } from './type';

const initialState: KpIzlogInitialState = {
  list: [],
  stat: {} as KpIzlogStatResults,
  updateTime: '',
  periodStart: '',
  periodEnd: '',
  isLoading: true,
  isLoadingCheckIdent: false,
};

const kpIzlogSlice = createSlice({
  name: 'kpIzlog',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'kpIzlog'>) => {
        if (!isEqual(state, action.payload[kpIzlogSlice.name])) {
          Object.assign(state, action.payload.kpIzlog);
        }
      }
    );

    builder.addCase(fetchKpIzlog.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchKpIzlog.fulfilled, (state, action) => {
      const {
        kpWindows = [],
        time = '',
        periodStart = '',
        periodEnd = '',
      } = action.payload;
      state.list = kpWindows;
      state.updateTime = time;
      state.periodStart = periodStart;
      state.periodEnd = periodEnd;
      state.isLoading = false;
    });

    builder.addCase(fetchKpIzlogStat.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchKpIzlogStat.fulfilled, (state, action) => {
      state.stat = action.payload;
      state.isLoading = false;
    });

    builder.addCase(checkKpIzlogIdent.pending, (state) => {
      state.isLoadingCheckIdent = true;
    });

    builder.addCase(checkKpIzlogIdent.fulfilled, (state) => {
      state.isLoadingCheckIdent = false;
    });

    builder.addCase(checkKpIzlogIdent.rejected, (state) => {
      state.isLoadingCheckIdent = false;
    });
  },
});

export default kpIzlogSlice;
