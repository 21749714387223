import { HYDRATE } from 'next-redux-wrapper';
import isEmpty from 'lodash/isEmpty';
import unset from 'lodash/unset';
import without from 'lodash/without';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { KEY_STATS } from '@lib/constants/meStats.constants';
import { extractByKey } from '@lib/extractByKey';
import { normalize } from '@lib/normalize';

import { routeChangeStart } from '@hooks/useRouteChangeStart';

import { createAd } from '@store/ad/model';
import { HydrateAction } from '@store/global/type';
import { changeUserStats } from '@store/meStats/thunk';

import { fetchUserAds } from './thunk';
import { MeAdsInitialState } from './type';

const initialState: MeAdsInitialState = {
  ads: {
    totalPages: 0,
    page: 1,
    adsIds: [],
    byId: {},
    isLoading: true,
    isNotFound: false,
    notificationType: 'none',
  },
  userAdsUrl: '',
  myAdsTitle: {
    deleted: 'Obaveštenja > Istekli oglasi',
    exp_soon: 'Obaveštenja > Oglasi ističu za 5 dana',
    parked_deleted: 'Obaveštenja > Istekli oglasi',
    parked_exp_soon: 'Obaveštenja > Parkirani oglasi ističu za 5 dana',
    early_renew_exp_soon: 'Obaveštenja > Obnovite oglase',
    renew_disc_exp_soon: 'Obaveštenja > Obnovite oglase',
  },
  goldInfoPromotion: {
    isGoldAdRequirementMet: false,
    isGoldProgressVisible: false,
    promoAdCount: 0,
    goldInfoTitle: '',
    goldInfoDescription: '',
  },
  isEditPriceChecked: false,
};

const meAdsSlice = createSlice({
  name: 'meAds',
  initialState,

  reducers: {
    resetNotificationType(state) {
      state.ads.notificationType = 'none';
    },
    setEditPriceChecked(state, action) {
      state.isEditPriceChecked = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'meAds'>) => {
        if (!isEqual(state, action.payload[meAdsSlice.name])) {
          Object.assign(state, action.payload.meAds);
        }
      }
    );

    builder.addCase(routeChangeStart, (state) => {
      state.ads = initialState.ads;
    });

    builder.addCase(fetchUserAds.pending, (state, action) => {
      const { query } = action.meta.arg;
      if (!query.skipLoading) {
        state.ads.isLoading = true;
      }
    });

    builder.addCase(fetchUserAds.fulfilled, (state, action) => {
      const {
        totalPages = 0,
        page = 1,
        userAdsUrl = '',
        isNotFound = false,
        notificationType = 'none',
        goldInfoPromotion,
      } = action.payload;
      const ads = action.payload.ads.map((ad) => createAd(ad));
      state.ads = {
        isLoading: false,
        adsIds: extractByKey(ads, 'id'),
        byId: normalize(ads, 'id'),
        totalPages,
        page,
        isNotFound,
        notificationType,
      };

      state.userAdsUrl = userAdsUrl;
      state.goldInfoPromotion = goldInfoPromotion;
    });

    builder.addCase(fetchUserAds.rejected, (state) => {
      state.ads.isLoading = false;
    });
    builder.addCase(changeUserStats, (state, action) => {
      const { key, adId = '' } = action.payload;

      if (key === KEY_STATS.DELETE_AD) {
        if (!isEmpty(state.ads.adsIds)) {
          const adsIds = without(state.ads.adsIds, adId);
          state.ads.adsIds = adsIds;
          unset(state.ads.byId, adId);
        }
      }
      if (key === KEY_STATS.PARKED_AD) {
        if (!isEmpty(state.ads.adsIds)) {
          const adsIds = without(state.ads.adsIds, adId);
          state.ads.adsIds = adsIds;
          unset(state.ads.byId, adId);
        }
      }
    });
  },
});

export const { resetNotificationType, setEditPriceChecked } =
  meAdsSlice.actions;

export default meAdsSlice;
