import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { HydrateAction } from '@store/global/type';

import { MyKpHeaderInitialState } from './type';

const initialState: MyKpHeaderInitialState = {
  displayMenu: false,
  stickyHeaderHeight: 68, // default header height
};

const MyKpHeaderSlice = createSlice({
  name: 'myKpHeader',
  initialState,

  reducers: {
    setDisplayMenu(state, action) {
      state.displayMenu = action.payload;
    },
    setStickyHeaderHeight(state, action) {
      state.stickyHeaderHeight = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'myKpHeader'>) => {
        if (!isEqual(state, action.payload[MyKpHeaderSlice.name])) {
          Object.assign(state, action.payload.myKpHeader);
        }
      }
    );
  },
});

export const { setDisplayMenu, setStickyHeaderHeight } =
  MyKpHeaderSlice.actions;

export default MyKpHeaderSlice;
