import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { uploadJobFiles } from '@store/file/thunk';
import { HydrateAction } from '@store/global/type';

import { FileInitialState } from './type';

const initialState: FileInitialState = {
  jobFiles: [],
  files: [],
  isDragActive: false,
  isLoading: false,
  isFileLoading: false,
};

const fileSlice = createSlice({
  name: 'file',
  initialState,

  reducers: {
    setFiles(state, action) {
      state.files = action.payload;
    },
    setIsFilesLoading(state, action) {
      state.isFileLoading = action.payload;
    },
    setIsDragActive(state, action) {
      state.isDragActive = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'file'>) => {
        if (!isEqual(state, action.payload[fileSlice.name])) {
          Object.assign(state, action.payload.file);
        }
      }
    );

    builder.addCase(uploadJobFiles.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(uploadJobFiles.fulfilled, (state, action) => {
      state.jobFiles = action.payload;
      state.isLoading = false;
    });

    builder.addCase(uploadJobFiles.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setFiles, setIsFilesLoading, setIsDragActive } =
  fileSlice.actions;

export default fileSlice;
