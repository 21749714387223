/* eslint-disable array-callback-return */
import Router from 'next/router';
import { HYDRATE } from 'next-redux-wrapper';
import isEmpty from 'lodash/isEmpty';
import unset from 'lodash/unset';
import without from 'lodash/without';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { extractByKey } from '@lib/extractByKey';
import { normalize } from '@lib/normalize';

import { SuccessfullyCreatedPrepaidInvoiceRoute } from 'server/routes';

import { routeChangeComplete } from '@hooks/useResetState';

import { HydrateAction } from '@store/global/type';
import {
  connectCardForm,
  createPrepaidInvoice,
  deleteUserCard,
  fetchOrderInfo,
  fetchPaymentSlip,
  fetchSmsPhoneCode,
  fetchSmsPrices,
  fetchTransactions,
  fetchTransactionSettings,
  fetchUserCards,
  setDefaultUserCard,
} from '@store/transaction/thunk';

import { createEBankInfo } from './model';
import {
  AllowedAmountsInfo,
  BankAccountBadgeInfo,
  OrderInfoResults,
  PaymentProcessingInfo,
  PrepaidBonusSection,
  PrepaidInvoiceInfo,
  TransactionInitialState,
} from './type';

const initialState: TransactionInitialState = {
  isLoading: true,
  cardIds: [],
  byId: {},
  list: [],
  totalPages: 0,
  errors: [],
  sms: {
    smsMtsPrice: '...',
    smsTelenorPrice: '...',
    smsVipPrice: '...',
    price: 300,
    priceDefault: 600,
    phone: '',
    smsShortCode: '',
    code: '',
  },
  bank: {
    qrCodeUrl: '',
    paymentSlipUrl: '',
    pdfUrl: '',
  },
  card: {
    action: '',
    hiddenFields: [],
    orderId: 0,
    errors: [],
    orderInfo: {} as OrderInfoResults,
    isLoading: true,
  },
  eBankInfo: {
    kpAccount: '220-104289-50',
    kpCompanyName: 'KP Advertajzing d.o.o. Beograd',
    modelNumber: 97,
    controlNumber: '',
    paymentCode: '',
    userType: '',
    userCompanyName: 'Konspo',
    userName: '',
  },
  allowedAmounts: [],
  selectedAmount: '2000,00',
  userTypeOptions: [
    { id: 'person', name: 'Fizičko lice' },
    { id: 'company', name: 'Pravno lice' },
  ],
  bankAccountBadgeInfo: {
    isBankAccountBadgeActive: false,
    isBankAccountBadgeExpireSoon: false,
    bankAccountBadgeStatusInactiveInfo:
      'Uz ovaj način plaćanja možete dobiti oznaku "Verifikovani bankovni račun".',
    bankAccountBadgeStatusActiveInfo:
      'Oznaka "Verifikovani bankovni račun" je aktivna. Ovim načinom uplate je zadržavate.',
    bankAccountBadgeStatusExpireSoonInfo:
      'Oznaka "Verifikovani bankovni račun" uskoro ističe. Ovim načinom uplate je zadržavate.',
    bankAccountBadgeGeneralInfoTitle:
      'Korisno: Oznaka “Verifikovani bankovni račun” u svakom Vašem oglasu',
    bankAccountBadgeActivationInfo:
      'Za ovu oznaku potrebno je uplatiti najmanje 5.228 din na KP Kreditu poslednja 3 meseca putem elektronskog ili mobilnog bankarstva.',
  },
  prepaidInvoiceInfo: { companyName: '', email: '', pib: 0, phone: '' },
  invoicePdf: '',
  isDownloadInvoiceLoading: false,
  prepaidInvoiceErrors: [],
  paymentProcessingInfo: { paymentProcessingMessage: '' },
  prepaidBonusSection: {
    prepaidBonusSectionTitle: '',
    prepaidBonusSectionDescription: '',
  },
  hasTransactions: false,
};

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'transaction'>) => {
        if (!isEqual(state, action.payload[transactionSlice.name])) {
          Object.assign(state, action.payload.transaction);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUserCards.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUserCards.fulfilled, (state, action) => {
      const { storedCards } = action.payload;
      state.isLoading = false;
      state.cardIds = extractByKey(storedCards, 'storedCardId');
      state.byId = normalize(storedCards, 'storedCardId');

      if (state.byId && !isEmpty(storedCards)) {
        const { storedCardId } = storedCards[0];
        state.byId[storedCardId].isDefault = true;
      }
    });

    builder.addCase(deleteUserCard.fulfilled, (state, action) => {
      const { storedCardId } = action.meta.arg;
      const cardIds = without(state.cardIds, storedCardId);
      state.cardIds = cardIds;
      unset(state.byId, storedCardId);
    });

    builder.addCase(setDefaultUserCard.fulfilled, (state, action) => {
      const { storedCardId } = action.meta.arg;
      state.isLoading = false;
      state.cardIds.map((id) => {
        if (id !== storedCardId) {
          state.byId[id].isDefault = false;
        }
        state.byId[storedCardId].isDefault = true;
      });
    });

    builder.addCase(fetchTransactions.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchTransactions.fulfilled, (state, action) => {
      const { transactions = [], totalPages = 1 } = action.payload;
      state.list = transactions;
      state.totalPages = totalPages;
      state.errors = [];
      state.isLoading = false;
    });

    builder.addCase(fetchTransactions.rejected, (state, action) => {
      state.list = [];
      state.isLoading = false;
      state.errors = action.payload;
    });

    builder.addCase(fetchSmsPrices.fulfilled, (state, action) => {
      state.sms = {
        ...state.sms,
        ...action.payload,
      };
    });

    builder.addCase(fetchSmsPhoneCode.fulfilled, (state, action) => {
      state.sms = {
        ...state.sms,
        ...action.payload,
      };
    });

    builder.addCase(fetchPaymentSlip.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchPaymentSlip.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bank.paymentSlipUrl = action.payload.url;
      state.bank.pdfUrl = action.payload.pdfUrl;
      state.bank.qrCodeUrl = action.payload.qrCodeUrl;
      state.eBankInfo = createEBankInfo(action.payload.info);
    });

    builder.addCase(connectCardForm.fulfilled, (state, action) => {
      state.card = action.payload;
    });

    builder.addCase(fetchOrderInfo.pending, (state) => {
      state.card.isLoading = true;
    });

    builder.addCase(fetchOrderInfo.fulfilled, (state, action) => {
      state.card.orderInfo = action.payload;
      state.card.isLoading = false;
    });

    builder.addCase(fetchTransactionSettings.fulfilled, (state, action) => {
      const {
        allowedAmountsInfo = {} as AllowedAmountsInfo,
        bankAccountBadgeInfo = {} as BankAccountBadgeInfo,
        prepaidInvoiceInfo = {} as PrepaidInvoiceInfo,
        paymentProcessingInfo = {} as PaymentProcessingInfo,
        prepaidBonusSection = {} as PrepaidBonusSection,
        hasTransactions = true,
      } = action.payload;
      state.allowedAmounts = allowedAmountsInfo.allowedAmounts;
      state.selectedAmount = allowedAmountsInfo.selectedAmount;
      state.bankAccountBadgeInfo = bankAccountBadgeInfo;
      state.prepaidInvoiceInfo = prepaidInvoiceInfo;
      state.paymentProcessingInfo = paymentProcessingInfo;
      state.prepaidBonusSection = prepaidBonusSection;
      state.hasTransactions = hasTransactions;
    });
    builder.addCase(createPrepaidInvoice.pending, (state) => {
      state.isDownloadInvoiceLoading = true;
      state.prepaidInvoiceErrors = [];
    });
    builder.addCase(createPrepaidInvoice.fulfilled, (state, action) => {
      const { invoicePdf } = action.payload;
      state.invoicePdf = invoicePdf;
      if (!Router.asPath.includes('/postavljanje-oglasa')) {
        Router.push(SuccessfullyCreatedPrepaidInvoiceRoute.generateUrl());
      }
      state.isDownloadInvoiceLoading = false;
    });
    builder.addCase(createPrepaidInvoice.rejected, (state, action) => {
      state.isDownloadInvoiceLoading = false;
      state.prepaidInvoiceErrors = action.payload;
    });
  },
});

export default transactionSlice;
