import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { getInitialPackageContent } from '@lib/shipping/getInitialPackageContent';
import { getInitialPaymentType } from '@lib/shipping/getInitialPaymentType';
import { getPackageCategories } from '@lib/shipping/getPackageCategories';
import { getPrimaryAddressId } from '@lib/shipping/getPrimaryAddressId';

import { Address } from '@store/address/type';
import { HydrateAction } from '@store/global/type';
import { fetchThread } from '@store/messageThread/thunk';

import {
  calculateShippingPrice,
  checkCourierFields,
  fetchCourierSettings,
  scheduleCourier,
} from './thunk';
import { CourierServices, ShippingCourierInitialState } from './type';

const initialState: ShippingCourierInitialState = {
  sender: {
    verifiedCompanyAccount: false,
    firstShippingOrder: true,
    addressList: [],
    lastPaymentType: null,
    lastBankAccount: '',
    shouldSelectFirstContent: false,
  },
  courier: {
    categories: [],
    weightRanges: [],
    metadata: {
      info: {
        packageContent: '',
        packageInsurance: '',
        packageReadyConfirmation: '',
        paymentInfoMessage: '',
      },
      order: {
        successful: {
          title: '',
          text: '',
        },
        unsuccessful: {
          title: '',
          text: '',
        },
      },
      contact: {
        title: '',
        generalInfo: '',
        text: '',
        phone: '',
      },
      safeOrderEducationalModalInfo: {
        title: '',
        description: '',
        forceDisplayExpiry: '',
        photoUrl: '',
        displayFrequency: 0,
        educationalModalEnabled: false,
      },
    },
    services: {
      cashBuyOutService: {} as CourierServices,
      bankBuyOutService: {} as CourierServices,
      packageInsuranceService: {} as CourierServices,
    },
  },

  shippingPrice: null,
  isLoading: false,
  errors: [],
  initialStep: 0,
  source: 'message',
  initialData: {
    address: '',
    payment: '',
    packageContent: '',
    bankBranchCode: '',
    bankAccountNumber: '',
    bankControlNumber: '',
  },
  receiverAddress: {} as Address,
  isNewCourierScheduling: true,
};

const shippingScheduleCourierSlice = createSlice({
  name: 'shippingScheduleCourier',
  initialState,
  reducers: {
    setInitialStep(state, action) {
      state.initialStep = action.payload;
    },
    setReceiverAddress(state, action) {
      state.receiverAddress = action.payload;
    },
    setIsNewCourierScheduling(state, action) {
      state.isNewCourierScheduling = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (
        state,
        action: HydrateAction<typeof initialState, 'shippingScheduleCourier'>
      ) => {
        if (
          !isEqual(state, action.payload[shippingScheduleCourierSlice.name])
        ) {
          Object.assign(state, action.payload.shippingCourier);
        }
      }
    );
    builder.addCase(fetchCourierSettings.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCourierSettings.fulfilled, (state, action) => {
      const { source } = action.meta.arg;
      const { sender, courier } = action.payload;
      state.courier = courier;
      state.sender = sender;
      state.isLoading = false;
      state.errors = [];

      const shouldOpenNewForm =
        state.isNewCourierScheduling || state.source !== source;

      const {
        lastBankAccount,
        lastPaymentType,
        firstShippingOrder,
        shouldSelectFirstContent,
        verifiedCompanyAccount,
        addressList,
      } = sender;
      const { categories } = courier;
      const packageCategories = getPackageCategories(categories);

      state.initialData = {
        address: getPrimaryAddressId(addressList),
        packageContent: getInitialPackageContent(
          firstShippingOrder,
          shouldSelectFirstContent,
          packageCategories
        ),
        payment: getInitialPaymentType({
          lastPaymentType,
          verifiedCompanyAccount,
        }),
        bankBranchCode: lastBankAccount?.slice(0, 3),
        bankAccountNumber: lastBankAccount?.slice(3, -3),
        bankControlNumber: lastBankAccount?.slice(-3),
      };

      if (shouldOpenNewForm) {
        state.initialStep = addressList ? 0 : 1;
        state.source = source;
      }
    });

    builder.addCase(checkCourierFields.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkCourierFields.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(checkCourierFields.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(calculateShippingPrice.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(calculateShippingPrice.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(calculateShippingPrice.fulfilled, (state, action) => {
      state.shippingPrice = action.payload.total;
      state.isLoading = false;
    });
    builder.addCase(scheduleCourier.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(scheduleCourier.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    });
    builder.addCase(scheduleCourier.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchThread.fulfilled, (state) => {
      state.isNewCourierScheduling = true;
    });
  },
});

export const { setInitialStep, setReceiverAddress, setIsNewCourierScheduling } =
  shippingScheduleCourierSlice.actions;

export default shippingScheduleCourierSlice;
