import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { normalize } from '@lib/normalize';

import { AntiBotResults } from '@store/antiBot/type';
import {
  fetchFeedbackInterceptor,
  fetchFeedbackSettings,
  fetchFeedbackThread,
  saveFeedback,
} from '@store/feedback/thunk';
import { fetchGeneralGroupedInfo } from '@store/generalStats/thunk';
import { HydrateAction } from '@store/global/type';

import { FeedbackAdditionalSettings, FeedbackInitialState } from './type';

const initialState: FeedbackInitialState = {
  activeStep: 0,
  isLoading: false,
  isFeedbackFormOpen: false,
  error: [],
  isFeedbackNpsSubmit: false,
  preselectedOption: '',
  isSupportReduced: false,
  thread: {
    isLoading: true,
    messages: [],
    isOpen: true,
    subject: '',
  },
  optionsType: [
    {
      id: 'prepaid',
      name: 'Problem sa uplatom ili promocijom',
    },
    {
      id: 'error',
      name: 'Tehnički problem',
    },
    {
      id: 'accountProblem',
      name: 'Problem sa nalogom',
    },
    {
      id: 'adProblem',
      name: 'Problem sa oglasom',
    },
    {
      id: 'reviewProblem',
      name: 'Ocenjivanje',
    },
    {
      id: 'fraud',
      name: 'Prevara (morate biti ulogovani)',
    },
    {
      id: 'problem',
      name: 'Ostali problemi',
    },
    {
      id: 'function',
      name: 'Predlog za poboljšanje',
    },
    {
      id: 'category',
      name: 'Nova kategorija/grupa',
    },
    {
      id: 'city',
      name: 'Novi grad/mesto',
    },
    {
      id: 'praise',
      name: 'Pohvala',
    },
    {
      id: 'comment',
      name: 'Razno',
    },
    {
      id: 'media',
      name: 'Kontakt za medije',
    },
    {
      id: 'copyrightInfringement',
      name: 'Copyright Infringement',
    },
  ],
  interceptor: {},
  feedbackSettings: {
    antibot: {} as AntiBotResults,
    settings: {
      allowedExtensions: {
        image: 'jpg,png,gif,heif,webp',
        document: '',
        video: '',
      },
      maxFileUploadSize: 26214400, // 25MB
    } as FeedbackAdditionalSettings,
  },
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,

  reducers: {
    setActiveStep(state, action) {
      state.activeStep = action.payload;
      state.isLoading = false;
    },
    setFeedbackFormOpen(state, action) {
      const isLastScreen = state.activeStep === 2;
      if (isLastScreen && !action.payload) {
        state.activeStep = 0;
        state.isLoading = false;
        state.isFeedbackFormOpen = false;
        state.error = [];
        state.isFeedbackNpsSubmit = false;
        state.preselectedOption = '';
      } else {
        state.isFeedbackFormOpen = action.payload;
      }
    },
    openFeedbackWithOption(state, action) {
      state.activeStep = 1;
      state.preselectedOption = action.payload;
      state.isFeedbackFormOpen = true;
    },
    removePreselectedFeedbackValue(state) {
      state.activeStep = 0;
      state.preselectedOption = '';
      state.isFeedbackFormOpen = false;
    },
    setFeedbackNpsSubmit(state, action) {
      state.isFeedbackNpsSubmit = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'feedback'>) => {
        if (!isEqual(state, action.payload[feedbackSlice.name])) {
          Object.assign(state, action.payload.feedback);
        }
      }
    );

    builder.addCase(saveFeedback.pending, (state) => {
      state.isLoading = true;
      state.error = [];
    });

    builder.addCase(saveFeedback.fulfilled, (state) => {
      state.isLoading = false;
      state.activeStep = 2;
      state.preselectedOption = '';
    });

    builder.addCase(saveFeedback.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchFeedbackThread.fulfilled, (state, action) => {
      const { messages, maxFileUploadSize, allowedExtensions, ...rest } =
        action.payload;
      state.thread.isLoading = false;
      state.thread = {
        ...state.thread,
        ...rest,
      };
      state.thread.messages = messages;
      state.feedbackSettings.settings = {
        maxFileUploadSize,
        allowedExtensions,
      };
    });

    builder.addCase(fetchGeneralGroupedInfo.fulfilled, (state, action) => {
      const { feedback = {} } = action.payload;
      const { isSupportReduced = false } = feedback;
      state.isSupportReduced = isSupportReduced;
    });

    builder.addCase(fetchFeedbackInterceptor.fulfilled, (state, action) => {
      state.interceptor = normalize(action?.payload ?? [], 'ident');
    });

    builder.addCase(fetchFeedbackSettings.fulfilled, (state, action) => {
      state.feedbackSettings = action.payload;
    });
  },
});

export const {
  setActiveStep,
  setFeedbackFormOpen,
  setFeedbackNpsSubmit,
  openFeedbackWithOption,
  removePreselectedFeedbackValue,
} = feedbackSlice.actions;

export default feedbackSlice;
