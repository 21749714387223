import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { routeChangeComplete } from '@hooks/useResetState';

import { fetchAd } from '@store/ad/thunk';
import {
  addToAddressBook,
  deleteFromAddressBook,
} from '@store/addressBook/thunk';
import { HydrateAction } from '@store/global/type';
import {
  activateAccountViaOAuth,
  fetchUserAdsSummary,
  fetchUserSummary,
  register,
  registerViaOAuth,
} from '@store/user/thunk';

import { createUser, User } from './model';
import { UserAdsSummary, UserInitialState } from './type';

const initialState: UserInitialState = {
  isLoading: false,
  summary: {} as User,
  loading: '',
  register: {
    errors: [],
  },
  summaryAds: {} as UserAdsSummary,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchAd.fulfilled, (state) => {
      state.summary = {} as User;
    });

    builder.addCase(addToAddressBook.fulfilled, (state) => {
      state.summary.isUserInAddressBook = true;
    });

    builder.addCase(deleteFromAddressBook.fulfilled, (state) => {
      state.summary.isUserInAddressBook = false;
    });

    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'user'>) => {
        if (!isEqual(state, action.payload[userSlice.name])) {
          Object.assign(state, action.payload.user);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      state.loading = '';
    });

    builder.addCase(fetchUserSummary.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUserSummary.fulfilled, (state, action) => {
      state.summary = createUser(action.payload);
      state.isLoading = false;
    });

    builder.addCase(fetchUserAdsSummary.fulfilled, (state, action) => {
      state.summaryAds = action.payload;
    });

    builder.addCase(register.pending, (state) => {
      state.register.errors = [];
      state.loading = 'direct';
    });

    builder.addCase(register.rejected, (state, action) => {
      state.loading = '';
      state.register.errors = action.payload;
    });

    builder.addCase(register.fulfilled, (state) => {
      state.loading = '';
    });

    builder.addCase(registerViaOAuth.pending, (state, action) => {
      state.register.errors = [];
      state.loading = action.meta.arg.socialNetwork;
    });

    builder.addCase(registerViaOAuth.rejected, (state, action) => {
      state.loading = '';
      state.register.errors = action.payload;
    });

    builder.addCase(activateAccountViaOAuth.pending, (state) => {
      state.loading = '';
    });

    builder.addCase(activateAccountViaOAuth.rejected, (state) => {
      state.loading = '';
    });
  },
});

export default userSlice;
