import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { HydrateAction } from '@store/global/type';
import { fetchDownloads } from '@store/mediakit/thunk';
import { MediakitInitialState } from '@store/mediakit/type';

const initialState: MediakitInitialState = {
  counter: 0,
  isLoading: false,
};

const mediaKitSlice = createSlice({
  name: 'mediaKit',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'mediaKit'>) => {
        if (!isEqual(state, action.payload[mediaKitSlice.name])) {
          Object.assign(state, action.payload.mediaKit);
        }
      }
    );

    builder.addCase(fetchDownloads.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchDownloads.fulfilled, (state, action) => {
      state.counter = action.payload.totalDownloads;
      state.isLoading = false;
    });
  },
});

export default mediaKitSlice;
