import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { HydrateAction } from '@store/global/type';
import { fetchStaticPages } from '@store/staticPages/thunk';

import { StaticPageResults, StaticPagesInitialState } from './type';

const initialState: StaticPagesInitialState = {
  staticContent: {} as StaticPageResults,
  isLoading: false,
};

const staticPagesSlice = createSlice({
  name: 'staticPages',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'staticPages'>) => {
        if (!isEqual(state, action.payload[staticPagesSlice.name])) {
          Object.assign(state, action.payload.staticPages);
        }
      }
    );

    builder.addCase(fetchStaticPages.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchStaticPages.fulfilled, (state, action) => {
      state.staticContent = action.payload;
      state.isLoading = false;
    });
  },
});

export default staticPagesSlice;
