import { Dispatch, ThunkDispatch } from '@reduxjs/toolkit';

import { Any, Error } from '@store/global/type';

type AdCheckValidateProps = {
  dispatch: ThunkDispatch<unknown, undefined, Any> & Dispatch<Any>;
  errors: Error[];
  toggleModal: (text: string) => Any;
  setShowErrorLinkFields: (fields: { [key: string]: boolean }) => Any;
};

const asyncAdCheckValidate = ({
  dispatch,
  errors,
  toggleModal,
  setShowErrorLinkFields,
}: AdCheckValidateProps) => {
  errors.forEach((error) => {
    if (error.error_code === 'service_top_full') {
      dispatch(toggleModal('[PROMO_NO_SLOTS]'));
    }

    if (error.error_code === 'description_forbiden_words') {
      dispatch(setShowErrorLinkFields({ showErrorDescriptionLink: true }));
    }

    if (error.error_code === 'name_forbiden_words') {
      dispatch(
        setShowErrorLinkFields({
          showErrorNameLink: true,
        })
      );
    }
  });
};

export default asyncAdCheckValidate;
