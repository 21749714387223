import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { routeChangeStart } from '@hooks/useRouteChangeStart';

import { HydrateAction } from '@store/global/type';

import { ModalInitialState } from './type';

const initialState: ModalInitialState = {
  activeModal: null,
  activeScreen: null,
  toast: {
    isActive: false,
    content: '',
    description: '',
    type: 'success',
    isAutoHide: true,
    isCloseable: false,
    duration: 4000,
  },
  isDescriptionScreenOpen: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,

  reducers: {
    toggleModal(state, action) {
      state.activeModal = action.payload;
    },
    toggleScreen(state, action) {
      state.activeScreen = action.payload;
    },
    showToast(state, action) {
      state.toast = {
        ...initialState.toast,
        ...action.payload,
        isActive: true,
      };
    },
    hideToast(state) {
      state.toast = initialState.toast;
    },
    setIsDescriptionScreenOpen(state, action) {
      state.isDescriptionScreenOpen = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'modal'>) => {
        if (!isEqual(state, action.payload[modalSlice.name])) {
          Object.assign(state, action.payload.modal);
        }
      }
    );

    builder.addCase(routeChangeStart, (state) => {
      state.activeModal = initialState.activeModal;
      state.activeScreen = initialState.activeScreen;
    });
  },
});

export const {
  toggleModal,
  toggleScreen,
  showToast,
  hideToast,
  setIsDescriptionScreenOpen,
} = modalSlice.actions;

export default modalSlice;
