import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { HydrateAction } from '@store/global/type';
import { fetchRenewerInfo, fetchRenewerPrices } from '@store/renewer/thunk';

import { RenewerInitialState } from './type';

const initialState: RenewerInitialState = {
  isLoading: true,
  info: {
    isActive: false,
    adRenewEnd: '...',
    oldPrice: '...',
    haveFunds: true,
    isActiveButton: true,
    showPaidPrice: true,
    isDiscountPrice: false,
    activationAvailableAfter: null,
    enabledNotifications: true,
    newExpirationDate: '...',
    price: '1.596',
    extendRenewerPrice: '...',
    reactivateRenewerPrice: '...',
    info: {
      title: '',
      description: [],
      additionalInfo: '',
      blogText: '',
      blogUrl: '',
      additionalInfoByRenewerStatus: '',
    },
  },
  rangs: {
    infoText:
      'Cena KP Obnavljača formira se automatski, na osnovu ukupnog broja Vaših oglasa iz sekcija Aktivni i Parkirani, izuzev oglasa čije se postavljanje plaća, u trenutku aktivacije usluge.',
    rangs: [],
  },
};

const renewerSlice = createSlice({
  name: 'renewer',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'renewer'>) => {
        if (!isEqual(state, action.payload[renewerSlice.name])) {
          Object.assign(state, action.payload.renewer);
        }
      }
    );

    builder.addCase(fetchRenewerInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.info = action.payload;
    });

    builder.addCase(fetchRenewerPrices.fulfilled, (state, action) => {
      state.rangs = action.payload;
    });
  },
});

export default renewerSlice;
