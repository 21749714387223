import Router from 'next/router';
import isEqual from 'lodash/isEqual';

import { Dispatch, ThunkDispatch } from '@reduxjs/toolkit';

import { AreYouHumanRoute } from '@server/routes';

import { setCaptchaSiteKey } from '@store/antiBot/slice';
import { Any, Error } from '@store/global/type';
import { toggleModal } from '@store/modal/slice';

import { SearchPayload } from './type';

export const checkLastSearchQuery = (getState: Any, payload: SearchPayload) => {
  const { follow } = payload.query;
  const lastSearchQuery = getState()?.search?.lastSearchQuery?.query;
  const isLastSearchEqual = isEqual(lastSearchQuery, payload?.query);

  if (isLastSearchEqual && follow !== 'yes') {
    return getState()?.search?.lastSearchResult;
  }

  return null;
};

export const handleAreYouHumanSoftError = (
  response: Any,
  dispatch: ThunkDispatch<unknown, undefined, Any> & Dispatch<Any>
) => {
  if (response.data?.results?.captcha === 'soft') {
    dispatch(setCaptchaSiteKey(response.data.results.captchaSiteKey));
    dispatch(toggleModal('areYouHuman'));
  }
};

export const handleAreYouHumanHardError = (
  response: Any,
  dispatch: ThunkDispatch<unknown, undefined, Any> & Dispatch<Any>
) => {
  response.errors.forEach((e: Error) => {
    if (e.error_code === 'areYouHuman') {
      dispatch(setCaptchaSiteKey(response.captchaSiteKey));
      if (e.error_description === 'hard') {
        Router.push(AreYouHumanRoute.generateUrl());
      }
    }
  });
};
