import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { fetchEmoji } from '@store/emoji/thunk';
import { HydrateAction } from '@store/global/type';

import { EmojiInitialState } from './type';

const initialState: EmojiInitialState = {
  list: [],
};

const emojiSlice = createSlice({
  name: 'emoji',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'emoji'>) => {
        if (!isEqual(state, action.payload[emojiSlice.name])) {
          Object.assign(state, action.payload.emoji);
        }
      }
    );

    builder.addCase(fetchEmoji.fulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});

export default emojiSlice;
