import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { routeChangeComplete } from '@hooks/useResetState';

import { HydrateAction } from '@store/global/type';
import { fetchInvoicePdf, fetchInvoices } from '@store/invoice/thunk';

import { InvoiceInitialState } from './type';

const initialState: InvoiceInitialState = {
  list: [],
  totalPages: 0,
  errors: [],
  isLoading: true,
  pdfLoading: {},
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'invoice'>) => {
        if (!isEqual(state, action.payload[invoiceSlice.name])) {
          Object.assign(state, action.payload.invoice);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchInvoices.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchInvoices.fulfilled, (state, action) => {
      const { invoices = [], totalPages = 0 } = action.payload;
      state.list = invoices;
      state.totalPages = totalPages;
      state.errors = [];
      state.isLoading = false;
    });

    builder.addCase(fetchInvoices.rejected, (state, action) => {
      state.list = [];
      state.isLoading = false;
      state.errors = action.payload;
    });

    builder.addCase(fetchInvoicePdf.pending, (state, action) => {
      const { id } = action.meta.arg;
      state.pdfLoading = { [id]: true };
    });

    builder.addCase(fetchInvoicePdf.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      state.pdfLoading = { [id]: false };
    });
  },
});

export default invoiceSlice;
