import { ROOT_URL } from './env.constants';

export const BACKUP_BANNERS_101 = [
  {
    bannerPositionId: 101,
    bannerId: 95047,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95047"><img src="/static/images/banners/Backup baner_300x250px-AUTOMOBILI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 101,
    bannerId: 95050,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95050"><img src="/static/images/banners/Backup baner_300x250px-POSLOVI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 101,
    bannerId: 95053,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95053"><img src="/static/images/banners/Backup baner_300x250px-zakazivanje_kurira.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 101,
    bannerId: 95056,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95056"><img src="/static/images/banners/Backup baner_300x250px-sacuvane_adrese.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_104 = [
  {
    bannerPositionId: 104,
    bannerId: 95047,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95047"><img src="/static/images/banners/Backup baner_300x250px-AUTOMOBILI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 104,
    bannerId: 95050,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95050"><img src="/static/images/banners/Backup baner_300x250px-POSLOVI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 104,
    bannerId: 95053,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95053"><img src="/static/images/banners/Backup baner_300x250px-zakazivanje_kurira.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 104,
    bannerId: 95056,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95056"><img src="/static/images/banners/Backup baner_300x250px-sacuvane_adrese.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_105 = [
  {
    bannerPositionId: 105,
    bannerId: 95047,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95047"><img src="/static/images/banners/Backup baner_300x250px-AUTOMOBILI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 105,
    bannerId: 95050,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95050"><img src="/static/images/banners/Backup baner_300x250px-POSLOVI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 105,
    bannerId: 95053,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95053"><img src="/static/images/banners/Backup baner_300x250px-zakazivanje_kurira.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 105,
    bannerId: 95056,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95056"><img src="/static/images/banners/Backup baner_300x250px-sacuvane_adrese.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_301 = [
  {
    bannerPositionId: 301,
    bannerId: 95049,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95049"><img src="/static/images/banners/Backup baner_970x250px-AUTOMOBILI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 301,
    bannerId: 95052,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95052"><img src="/static/images/banners/Backup baner_970x250px-POSLOVI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 301,
    bannerId: 95055,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95055"><img src="/static/images/banners/Backup baner_970x250px-zakazivanje_kurira.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 301,
    bannerId: 95058,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95058"><img src="/static/images/banners/Backup baner_970x250px-sacuvane_adrese.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_302 = [
  {
    bannerPositionId: 302,
    bannerId: 95048,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95048"><img src="/static/images/banners/Backup baner_300x600px-AUTOMOBILI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 302,
    bannerId: 95051,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95051"><img src="/static/images/banners/Backup baner_300x600px-POSLOVI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 302,
    bannerId: 95054,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95054"><img src="/static/images/banners/Backup baner_300x600px-zakazivanje_kurira.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 302,
    bannerId: 95057,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95057"><img src="/static/images/banners/Backup baner_300x600px-sacuvane_adrese.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_306 = [
  {
    bannerPositionId: 306,
    bannerId: 95047,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95047"><img src="/static/images/banners/Backup baner_300x250px-AUTOMOBILI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 306,
    bannerId: 95050,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95050"><img src="/static/images/banners/Backup baner_300x250px-POSLOVI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 306,
    bannerId: 95053,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95053"><img src="/static/images/banners/Backup baner_300x250px-zakazivanje_kurira.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 306,
    bannerId: 95056,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95056"><img src="/static/images/banners/Backup baner_300x250px-sacuvane_adrese.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
];

export const BACKUP_BANNERS_308 = [
  {
    bannerPositionId: 308,
    bannerId: 95047,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95047"><img src="/static/images/banners/Backup baner_300x250px-AUTOMOBILI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 308,
    bannerId: 95050,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95050"><img src="/static/images/banners/Backup baner_300x250px-POSLOVI.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 308,
    bannerId: 95053,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95053"><img src="/static/images/banners/Backup baner_300x250px-zakazivanje_kurira.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
  {
    bannerPositionId: 308,
    bannerId: 95056,
    bannerType: 'html',
    bannerData: {
      html: `<a rel="nofollow" target="_blank" href="${ROOT_URL}/bClick.php?id=95056"><img src="/static/images/banners/Backup baner_300x250px-sacuvane_adrese.png" alt="" border="0"></a>`,
      info2: '',
    },
  },
];
