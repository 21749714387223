import Router from 'next/router';
import { Dispatch } from 'redux';

import { ThunkDispatch } from '@reduxjs/toolkit';

import { signUpEvents } from '@lib/events/signUp.event';

import {
  FacebookErrorRoute,
  MyKPAdsRoute,
  UserBlockedRoute,
} from '@server/routes';

import { setToken } from '@store/auth/thunk';
import { Any, Error } from '@store/global/type';

import { OAuthRegisterResponse } from './type';

export const onSuccessRegister = async (
  data: OAuthRegisterResponse,
  socialNetwork: string,
  dispatch: ThunkDispatch<unknown, undefined, Any> & Dispatch<Any>
) => {
  if (data.activated) {
    signUpEvents.successRegisterActivation(socialNetwork);
  } else if (data.login) {
    signUpEvents.successLogin(socialNetwork);
  } else {
    signUpEvents.successRegister(socialNetwork);
  }

  await dispatch(setToken(data?.token));
  Router.push(
    MyKPAdsRoute.generateUrl({}, { activation: data.activated ? '1' : '' })
  );
};

export const onErrorRegister = (e: Any) => {
  const { errors } = e.response.data;
  if (e?.response?.data?.errors) {
    errors.forEach((error: Error) => {
      if (error.error_code === 'user_status_declined') {
        signUpEvents.errorDecline();
        Router.push(UserBlockedRoute.generateUrl());
      } else if (error.error_code === 'oauth_email_exist') {
        signUpEvents.failFbNoEmail();
        Router.push(FacebookErrorRoute.generateUrl());
      }
    });
  }
};
