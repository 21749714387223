import { apiRequest } from '@store/api/request/makeRequest';
import type { ThunkAPI } from '@store/configureStore';

import type {
  DeleteMessagePayload,
  MessageInboxPayload,
  MessageListFilterAdsResponse,
  MessageListFilterUsersResponse,
  MessageListResponse,
  ToggleStarMessageThreadPayload,
} from './type';
import { searchParams } from './utils';

const MESSAGE_URL = 'message';

const fetchInbox = (thunkAPI: ThunkAPI) => (payload: MessageInboxPayload) => {
  const searchUrlParams = searchParams(payload);

  return apiRequest<MessageListResponse>(
    `${MESSAGE_URL}/inbox?${searchUrlParams}`,
    {
      method: 'GET',
      thunkAPI,
    }
  );
};

const fetchMessageUsers =
  (thunkAPI: ThunkAPI) => (payload?: MessageInboxPayload) => {
    const searchUrlParams = searchParams(payload);
    return apiRequest<MessageListFilterUsersResponse>(
      `${MESSAGE_URL}/users?${searchUrlParams}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

const fetchMessageAds =
  (thunkAPI: ThunkAPI) => (payload?: MessageInboxPayload) => {
    const searchUrlParams = searchParams(payload);
    return apiRequest<MessageListFilterAdsResponse>(
      `${MESSAGE_URL}/eds?${searchUrlParams}`,
      {
        method: 'GET',
        thunkAPI,
      }
    );
  };

const deleteMessage = (thunkAPI: ThunkAPI) => (body: DeleteMessagePayload) =>
  apiRequest(`${MESSAGE_URL}/delete`, {
    method: 'POST',
    body,
    thunkAPI,
  });

const toggleStarMessageThread =
  (thunkAPI: ThunkAPI) => (body: ToggleStarMessageThreadPayload) =>
    apiRequest(`${MESSAGE_URL}/thread-attributes/starred`, {
      method: 'POST',
      body,
      thunkAPI,
    });

export const MessageAPI = {
  fetchInbox,
  fetchMessageUsers,
  fetchMessageAds,
  deleteMessage,
  toggleStarMessageThread,
} as const;
