import React from 'react';
import dynamic from 'next/dynamic';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectModal, selectScreen } from '@store/modal/selector';
import { selectDisplayMenu } from '@store/myKpHeader/selector';

const FeedbackForm = dynamic(
  () => import('@components/feedback/mobile/FeedbackForm/FeedbackForm')
);
const CurrencyConverterScreen = dynamic(
  () =>
    import(
      '@components/common/mobile/CurrencyConverter/CurrencyConverterScreen'
    )
);

const MyKpMenu = dynamic(
  () => import('@components/common/mobile/MyKpMenu/MyKpMenu')
);

const ScheduleCourierSheet = dynamic(
  () =>
    import('@components/shipping/mobile/ScheduleCourier/ScheduleCourierSheet')
);

const ScheduleCourierEducationSheet = dynamic(
  () =>
    import(
      '@components/shipping/mobile/ScheduleCourierEducationSheet/ScheduleCourierEducationSheet'
    )
);

const MobileCoreLayoutComponents = () => {
  const activeModal = useAppSelector(selectModal);
  const activeScreen = useAppSelector(selectScreen);
  const isOpen = useAppSelector(selectDisplayMenu);

  const componentsToRender = [
    { condition: isOpen, component: <MyKpMenu /> },
    { condition: activeScreen === 'feedbackForm', component: <FeedbackForm /> },
    {
      condition: activeScreen === 'NBS-screen',
      component: <CurrencyConverterScreen />,
    },
    {
      condition: activeModal === '[SCHEDULE_COURIER_EDUCATION]',
      component: <ScheduleCourierEducationSheet />,
    },
    {
      condition: activeModal === '[SCHEDULE_COURIER_MODAL]',
      component: <ScheduleCourierSheet />,
    },
  ];

  return (
    <>
      {componentsToRender.map(
        (item, index) =>
          item.condition && (
            <React.Fragment key={index}>{item.component}</React.Fragment>
          )
      )}
    </>
  );
};

export default MobileCoreLayoutComponents;
