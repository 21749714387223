import isEmpty from 'lodash/isEmpty';

import { MY_ADS_STATUS } from '@lib/constants/myAdCountMapper.constants';
import { formatNumberWithKSuffix } from '@lib/formatNumberWithKSuffix';
import { formatNumber } from '@lib/localization/formatNumber';
import { prependImageUrl } from '@lib/prependImageUrl';
import { decodeHTML } from 'src/lib/decodeHTML';
import { getCondition } from 'src/lib/getCondition';
import { getPriceText } from 'src/lib/getPriceText';
import { getWordPluralForm } from 'src/lib/getWordPluralForm';
import { formatCurrency } from 'src/lib/localization/formatCurrency';

import { createAdCarInformation } from '@store/customValue/model';
import { createPhoto, Photo } from '@store/models/Photo/Photos';

import { createUser } from '../user/model';

import { Ad } from './type';

const createHasService = (ad) =>
  ad.is_top ||
  ad.is_priority ||
  ad.is_highlighted ||
  ad.is_top_search ||
  ad.is_video ||
  ad.is_link ||
  ad.is_status_gold;

const createAdLinks = (ad) => ({
  isVideo: ad.is_video ?? null,
  isLink: ad.is_link ?? null,
  preparedWebsite: ad.prepared_website ?? '',
  website: !isEmpty(ad.website) ? ad.website : '',
  video: !isEmpty(ad.video_url) ? ad.video_url : '',
  linkEndDate: ad.link_end_date ?? '',
  videoEndDate: ad.video_end_date ?? '',
});

const createAdPromoInfo = (ad) => ({
  hasService: createHasService(ad),
  serviceType: ad.promo_type,
  isTop: ad.is_top ?? null,
  isPriority: ad.is_priority ?? null,
  isHighlighted: ad.is_highlighted ?? null,
  isTopSearch: ad.is_top_search ?? null,
  isPurchaseLink: ad.is_purchase_link ?? null,
  isStatusGold: ad.is_status_gold || false,
  isDisplayGoldHeader: ad.is_display_gold_header || false,
  isDisplayPriority: ad.is_display_priority || false,
  isDisplayTop: ad.is_display_top || false,
  isDisplayGold: ad.is_display_top_gold || false,
  isDisplayTopSearch: ad.is_display_top_search || false,
  isDisplayTopSearchGold: ad.is_display_top_search_gold || false,
  priorityEndDate: ad.priority_end_date ?? '',
  topEndDate: ad.top_end_date ?? '',
  highlightedEndDate: ad.highlighted_end_date ?? '',
  ...createAdLinks(ad),
});

const createAdBookInfo = (ad) => ({
  isbn: ad.isbn || 0,
  bookAuthor: ad.book_author || '',
  bookPages: ad.book_pages || '',
  bookFormat: ad.book_binding || '',
});

const createAdCarInfo = (ad) => ({
  isCar: ad.is_car,
  carInformation: ad.car_information
    ? createAdCarInformation(ad.car_information)
    : null,
  carOptions: ad.car_options ?? null,
  carNotes: ad.car_notes ?? null,
  carMakeYear: ad.vehicle_make_year ?? null,
  carKm: `${formatNumber(ad.vehicle_km)} km`,
  carCc: `${ad.vehicle_cc}cm`,
  carFuelType: ad.car_fuel_type_name ?? null,
  carModelDesc: ad.car_model_desc ?? null,
});

const createAdPhotoInfo = (ad) => ({
  image: prependImageUrl(ad.photo_path1) ?? null,
  smallImage: prependImageUrl(ad.photo1_tmb_300x300) ?? null,
  hasImage: !ad.photo1_tmb_300x300?.includes('no_photo'),
  photos: ad.photos ? ad.photos.map((photo: Photo) => createPhoto(photo)) : [],
});

const createAdPriceInfo = (ad) => ({
  price: formatCurrency(ad.price),
  priceNumber: ad.price,
  currencyAcronym: ad.currency,
  currency: ad.currency === 'rsd' ? 'din' : '€',
  priceText:
    ad.price_text ||
    getPriceText(
      ad.ad_type,
      ad.ad_kind,
      ad.price,
      ad.currency === 'rsd' ? 'din' : '€'
    ),
  priceDisplay: ad.price_display ?? '',
  creditInfo: !isEmpty(ad.creditInfo) ? ad.creditInfo : {},
  isExchange: ad.exchange || false,
  isPriceFixed: ad.is_price_fixed || false,
  adCreatePrice: ad.adCreatePrice || 0,
});

const createAdJobInfo = (ad) => ({
  isJob: ad.is_job,
  jobApplicationType: ad.job_application_type ?? '',
  jobApplicationLink: ad.job_application_link ?? '',
  jobApplicationPhone: ad.job_application_phone ?? '',
});

const createAdDeliveryInfo = (ad) => ({
  isImmediateAvailable: ad.is_immediate_available || false,
  isImmediateAvailableAllowed: ad.is_immediate_available_allowed || false,
  isLocalPickupAvailable: ad.is_local_pickup_available || false,
  isCourierDeliveryAvailable: ad.is_courier_delivery_available || false,
  localPickupDetailedInfo: ad.local_pickup_detailed_info || '',
  courierDeliveryDetailedInfo: ad.courier_delivery_detailed_info || '',
});

const createAdRegistrationNumbers = (ad) => ({
  regNumberRealEstateAgentId: ad.reg_number_real_estate_agent_id_value ?? '',
  regNumberHid: ad.reg_number_hid_value ?? '',
  regNumberHealthcareLicenseId: ad.reg_number_healthcare_license_id_value ?? '',
  regNumberApartmentsCategorizationId:
    ad.reg_number_apartments_categorization_id_value ?? '',
  regNumberPetOwnerId: ad.reg_number_pet_owner_id_value ?? '',
});

const createAdMainInfo = (ad) => ({
  id: ad.ad_id,
  userId: ad.user_id,
  user: createUser(ad.user),
  ownerName: decodeHTML(ad.owner) ?? '',
  ownerPhone: ad.phone ?? '',
  name: decodeHTML(ad.name),
  formattedName: ad.is_car
    ? `${ad.vehicle_make_year} ${decodeHTML(ad.name)} ${
        ad.car_model_desc ? ad.car_model_desc : ''
      }`
    : decodeHTML(ad.name),
  description: ad.description_decoded ?? null,
  descriptionSnippetDecoded: ad.description_snippet_decoded ?? '',
  postedRaw: ad.posted ?? '',
  postedDays: ad.posted_days_desc ?? '',
  posted: ad.posted_desc ?? '',
  postedDesc: ad.posted_desc ?? '',
  postedBeforeDescription: ad.posted_desc ?? '',
  categoryName: ad.category_name,
  categoryId: ad.category_id,
  groupName: ad.group_name,
  groupId: ad.group_id,
  type: ad.ad_type,
  adKind: ad.ad_kind,
  adClass: ad.ad_class,
  conditionId: ad.condition,
  condition: getCondition(ad.condition) ?? null,
  location: ad.location_name,
  declarationType: ad.declaration_type,
  isReviewShown: ad.is_review_shown ?? true,
  adUrl: ad.ad_url,
  kpizlog: ad.kpizlog ?? null,
  kpizlogUrl: ad.kpizlog_url || '',
  isOwner: ad.is_owner ?? false,
  isMessageButtonHidden: ad.is_message_button_hidden ?? false,
  bankInfo: ad.bankInfo ?? {},
  oem: ad.oem || '',
  status: ad.status || '',
  draftAdViewInfoText: ad.draftAdViewInfoText ?? {
    alertDesc: '',
    alertTitle: '',
    activationInterceptorTitle: '',
    activationInterceptorDesc: '',
  },
});

const createAdStatsInfo = (ad) => ({
  viewCount: formatCurrency(ad.view_count),
  viwCountKSuffix: formatNumberWithKSuffix(ad.view_count),
  viewCountCaption: getWordPluralForm({
    count: ad.view_count,
    singularWord: 'put',
    fewWord: 'puta',
    manyWord: 'puta',
    zeroWord: 'puta',
  }),
  favoriteCount: ad.favorite_count,
  favoriteCountKSuffix: formatNumberWithKSuffix(ad.favorite_count),
  favoriteText: ad.favorite_count
    ? `${ad.favorite_count} ${getWordPluralForm({
        count: ad.favorite_count,
        singularWord: 'korisnik',
        fewWord: 'korisnika',
        manyWord: 'korisnika',
        zeroWord: 'korisnika',
      })}`
    : '',
  messageCount: ad.message_count || 0,
});

const createAdState = (ad) => ({
  isParked: ad.status === MY_ADS_STATUS.parked,
  isAdDeleted: ad.status === MY_ADS_STATUS.deleted,
  isRenewed: ad.is_renewed ?? null,
  adValidUntil: ad.ad_valid_until,
  updateRequired: ad.update_required ?? false,
});

const createAdInteractionStatus = (ad) => ({
  isFollowing: ad.is_following_ad || false,
  adUserIsIgnored: ad.ad_user_is_ignored || false,
});

export const createAd = (ad): Ad => ({
  ...createAdMainInfo(ad),
  ...createAdStatsInfo(ad),
  ...createAdPriceInfo(ad),
  ...createAdPhotoInfo(ad),
  ...createAdPromoInfo(ad),
  ...createAdJobInfo(ad),
  ...createAdCarInfo(ad),
  ...createAdRegistrationNumbers(ad),
  ...createAdDeliveryInfo(ad),
  ...createAdBookInfo(ad),
  ...createAdState(ad),
  ...createAdInteractionStatus(ad),
});
