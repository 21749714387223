import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { extractByKey } from '@lib/extractByKey';
import { normalize } from '@lib/normalize';

import { HydrateAction, RequestStatus } from '@store/global/type';

import { fetchMessageShipmentTrackCourier } from './thunk';
import { ShipmentTrackCourierInitialState } from './type';

const initialState: ShipmentTrackCourierInitialState = {
  messageShipmentTrackCourierById: [],
  messageShipmentTrackCourierIds: [],
  isLoadingMessageShipmentTrackCourier: RequestStatus.idle,
};

const shipmentTrackCourierSlice = createSlice({
  name: 'shipmentTrackCourier',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (
        state,
        action: HydrateAction<typeof initialState, 'shipmentTrackCourier'>
      ) => {
        if (!isEqual(state, action.payload[shipmentTrackCourierSlice.name])) {
          Object.assign(state, action.payload.shipmentTrackCourier);
        }
      }
    );

    builder.addCase(fetchMessageShipmentTrackCourier.pending, (state) => {
      state.isLoadingMessageShipmentTrackCourier = RequestStatus.pending;
    });

    builder.addCase(
      fetchMessageShipmentTrackCourier.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.messageShipmentTrackCourierById = normalize(
            action.payload,
            'id'
          );
          state.messageShipmentTrackCourierIds = extractByKey(
            action.payload,
            'id'
          );
          state.isLoadingMessageShipmentTrackCourier = RequestStatus.fulfilled;
        }
      }
    );

    builder.addCase(fetchMessageShipmentTrackCourier.rejected, (state) => {
      state.isLoadingMessageShipmentTrackCourier = RequestStatus.rejected;
    });
  },
});

export default shipmentTrackCourierSlice;
