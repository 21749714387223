import { HYDRATE } from 'next-redux-wrapper';
import unset from 'lodash/unset';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { normalize } from '@lib/normalize';

import { routeChangeComplete } from '@hooks/useResetState';

import { HydrateAction } from '@store/global/type';
import {
  deleteSavedSearches,
  fetchSavedSearches,
  updateSavedSearches,
} from '@store/savedSearch/thunk';

import { SavedSearchesInitialState } from './type';

const initialState: SavedSearchesInitialState = {
  list: [],
  pages: 0,
  isLoading: true,
};

const savedSearchSlice = createSlice({
  name: 'savedSearch',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'savedSearch'>) => {
        if (!isEqual(state, action.payload[savedSearchSlice.name])) {
          Object.assign(state, action.payload.savedSearch);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      Object.assign(state, initialState);
    });

    builder.addCase(fetchSavedSearches.fulfilled, (state, action) => {
      const { results, pages } = action.payload;
      state.isLoading = false;
      state.pages = pages;
      state.list = normalize(results, 'search_id');
    });

    builder.addCase(fetchSavedSearches.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateSavedSearches.fulfilled, (state, action) => {
      const {
        search_id: id,
        data: { notification },
      } = action.meta.arg;

      state.list[id].notification = notification;
    });

    builder.addCase(deleteSavedSearches.fulfilled, (state, action) => {
      const { search_id: id } = action.meta.arg;
      unset(state.list, id);
    });
  },
});

export default savedSearchSlice;
