/* eslint-disable import/no-cycle */
import Router from 'next/router';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { normalizePhone } from '@lib/normalizers/normalizePhone';
import { omitFalsy } from '@lib/omitFalsy';

import { routeChangeComplete } from '@hooks/useResetState';

import {
  checkAd,
  fetchUserOwnedAd,
  saveAd,
  saveAdSpecificFields,
} from '@store/adSave/thunk';
import {
  AdCheckResponse,
  AdSaveInitialState,
  AdSavePhoneDisplay,
  DeliveryMethodsInfo,
} from '@store/adSave/type';
import { HydrateAction } from '@store/global/type';
import { fetchGroups } from '@store/group/thunk';
import { fetchUserProfile } from '@store/meProfile/thunk';

const initialState: AdSaveInitialState = {
  activeStep: 0,
  steps: [
    {
      title: 'Izbor kategorije',
    },
    {
      title: 'Unos oglasa',
    },
    {
      title: 'Izbor promocije',
    },
    {
      title: 'Identifikacija',
    },
  ],
  isInitialDataFetched: false,
  initialData: {},
  recentGroups: [],
  finished: false,
  errors: [],
  userErrors: {},
  options: {
    showExchange: true,
    showCondition: true,
    adClass: 'basic',
    kind: '',
    declarationType: 'all',
    topMaxAds: 10,
    topUserMaxAds: 5,
    adPhotosMax: 15,
    agreementIds: [],
    isPsvActive: false,
    isDeliveryMethodAllowed: false,
  },
  id: '',
  parentId: '',
  captcha: {
    showCaptcha: false,
    captchaSiteKey: '',
  },
  phoneDisplayControl: {
    isPhoneNumberEditable: false,
    isPhoneNumberVisible: false,
    phoneDisplayControlTooltip: null,
    isPhoneDisplayControlEnabled: false,
  },
  isEditAd: false,
  uniqueStamp: '',
  isStepNextLoading: false,
  isHeaderBackClicked: false,
  asBackPath: '',
  quickEditAdId: null,
  isQuickEditLoading: false,
  immediateAvailableInfo: '',
  deliveryMethodsInfo: {
    deliveryMethodInfo: '',
    deliveryMethodCourierDeliveryInfo: '',
    deliveryMethodLocalPickupInfo: '',
  },
  isIsbnScanSupported: false,
  showErrorLinkFields: {
    showErrorDescriptionLink: false,
    showErrorNameLink: false,
  },
  isPromotionItemRequired: false,
  isGroupInfoFetched: false,
  isStepBack: false,
};

const adSaveSlice = createSlice({
  name: 'adSave',
  initialState,

  reducers: {
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    setStepBack(state) {
      state.activeStep -= 1;
      state.isStepBack = true;
    },
    setOptions(state, action) {
      state.options = action.payload;
    },
    setSelectedGroup(state, action) {
      const { categoryId, groupId } = action.payload;
      const isEditad = state.isEditAd;
      state.parentId = categoryId;
      state.id = groupId;
      if (groupId && !isEditad) {
        state.isStepNextLoading = true;
      }
    },
    setUniqueStamp(state, action) {
      state.uniqueStamp = action.payload;
    },
    setEditAd(state, action) {
      const { updateRequired = false } = action.payload;
      state.isEditAd = true;
      state.activeStep = updateRequired ? 2 : 1;
      state.steps = [
        {
          title: 'Izbor kategorije',
        },
        {
          title: 'Unos oglasa',
        },
        {
          title: 'Identifikacija',
        },
      ];
    },
    setHeaderBackClicked(state, action) {
      state.isHeaderBackClicked = action.payload;
    },
    setAsBackPath(state, action) {
      state.asBackPath = action.payload;
    },
    setInitialDescriptionValue(state, action) {
      state.initialData.description = action.payload;
    },
    resetQuickEditAdId(state) {
      state.quickEditAdId = null;
    },
    setShowErrorLinkFields(state, action) {
      state.showErrorLinkFields = {
        ...state.showErrorLinkFields,
        ...action.payload,
      };
    },
    clearShowErrorLinkFields(state) {
      state.showErrorLinkFields = initialState.showErrorLinkFields;
    },
    setPromoItemRequired(state, action) {
      state.isPromotionItemRequired = action.payload;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'adSave'>) => {
        if (!isEqual(state, action.payload[adSaveSlice.name])) {
          Object.assign(state, action.payload.adSave);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      const { isEditAd } = state;
      if (!isEditAd && !Router.asPath.includes('/postavljanje-oglasa')) {
        Object.assign(state, initialState);
      }
    });

    builder.addCase(fetchUserOwnedAd.pending, (state) => {
      const {
        isInitialDataFetched,
        initialData,
        recentGroups,
        userErrors,
        phoneDisplayControl,
      } = initialState;
      state.isInitialDataFetched = isInitialDataFetched;
      state.initialData = initialData;
      state.recentGroups = recentGroups;
      state.userErrors = userErrors;
      state.phoneDisplayControl = phoneDisplayControl;
    });

    builder.addCase(fetchUserOwnedAd.fulfilled, (state, action) => {
      const {
        phoneDisplayControl = {} as AdSavePhoneDisplay,
        groupSuggestions,
        errors = {},
        phone,
        carDoors = '',
        vehicleMakeYear = '',
        vehicleSeats = '',
        immediateAvailableInfo = '',
        deliveryMethodInfo = '',
        deliveryMethodCourierDeliveryInfo = '',
        deliveryMethodLocalPickupInfo = '',
        ...rest
      } = action.payload;
      state.isInitialDataFetched = true;
      state.initialData = {
        ...omitFalsy(rest),
        ...(phone && { phone: normalizePhone(phone) }),
        ...(rest.name && { groupSuggestInputText: rest.name }),
        ...(carDoors && { carDoors: String(carDoors) }),
        ...(vehicleSeats && { vehicleSeats: String(vehicleSeats) }),
        ...(vehicleMakeYear && { vehicleMakeYear: `${vehicleMakeYear}.` }),
      };
      state.recentGroups = groupSuggestions;
      state.userErrors = errors;
      state.phoneDisplayControl = phoneDisplayControl;
      state.immediateAvailableInfo = immediateAvailableInfo;
      state.deliveryMethodsInfo = {
        deliveryMethodInfo,
        deliveryMethodCourierDeliveryInfo,
        deliveryMethodLocalPickupInfo,
      };
    });

    builder.addCase(fetchUserOwnedAd.rejected, (state, action) => {
      state.userErrors = action.payload ?? {};
    });

    builder.addCase(fetchGroups.fulfilled, (state) => {
      state.isGroupInfoFetched = true;
    });

    builder.addCase(checkAd.pending, (state) => {
      state.isStepNextLoading = true;
    });

    builder.addCase(checkAd.fulfilled, (state, action) => {
      const {
        showCaptcha = false,
        captchaSiteKey = '',
        activeStep,
        phoneDisplayControl = {} as AdSavePhoneDisplay,
        isIsbnScanSupported = false,
      } = action.payload as AdCheckResponse;
      const { isEditAd } = state;
      state.isStepNextLoading = false;
      state.captcha.showCaptcha = showCaptcha;
      state.captcha.captchaSiteKey = captchaSiteKey;
      state.phoneDisplayControl = phoneDisplayControl;
      state.isIsbnScanSupported = isIsbnScanSupported;

      if (!isEditAd && activeStep < 3) {
        state.activeStep += 1;
        state.isStepBack = false;
      }
    });

    builder.addCase(checkAd.rejected, (state, action) => {
      state.isStepNextLoading = false;
      state.errors = action.payload;
    });

    builder.addCase(saveAd.pending, (state) => {
      state.isStepNextLoading = true;
    });

    builder.addCase(saveAd.rejected, (state, action) => {
      state.isStepNextLoading = false;
      state.errors = action.payload;
    });

    builder.addCase(saveAdSpecificFields.pending, (state) => {
      state.isQuickEditLoading = true;
    });

    builder.addCase(saveAdSpecificFields.fulfilled, (state, action) => {
      state.quickEditAdId = action.payload.ad_id;
      state.isQuickEditLoading = false;
    });

    builder.addCase(saveAdSpecificFields.rejected, (state) => {
      state.isQuickEditLoading = false;
    });

    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      const { deliveryMethodAllAdsInfo = {} as DeliveryMethodsInfo } =
        action.payload;
      const {
        deliveryMethodCourierDeliveryInfo = '',
        deliveryMethodLocalPickupInfo = '',
      } = deliveryMethodAllAdsInfo;
      state.deliveryMethodsInfo = {
        ...state.deliveryMethodsInfo,
        deliveryMethodCourierDeliveryInfo,
        deliveryMethodLocalPickupInfo,
      };
    });
  },
});

export const {
  setActiveStep,
  setStepBack,
  setOptions,
  setSelectedGroup,
  setEditAd,
  resetState,
  setUniqueStamp,
  setHeaderBackClicked,
  setAsBackPath,
  resetQuickEditAdId,
  setInitialDescriptionValue,
  setShowErrorLinkFields,
  clearShowErrorLinkFields,
  setPromoItemRequired,
} = adSaveSlice.actions;

export default adSaveSlice;
