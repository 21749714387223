import { HYDRATE } from 'next-redux-wrapper';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { routeChangeComplete } from '@hooks/useResetState';

import { HydrateAction } from '@store/global/type';
import { createNotification } from '@store/notification/model';
import { fetchNotifications, markAsRead } from '@store/notification/thunk';

import { NotificationInitialState } from './type';

const initialState: NotificationInitialState = {
  list: [],
  firstPosted: '',
  lastPosted: '',
  lastStatusChanged: '',
  isLoading: true,
  moreNotifications: false,
  totalNotifications: null,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,

  reducers: {
    resetState(state) {
      Object.assign(state, initialState);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'notification'>) => {
        if (!isEqual(state, action.payload[notificationSlice.name])) {
          Object.assign(state, action.payload.notification);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      Object.assign(state, initialState);
    });

    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      const newNotification = action.payload?.notifications?.map(
        (notification) => createNotification(notification)
      );
      state.isLoading = false;
      state.list = [...state.list, ...newNotification];
      state.moreNotifications = action.payload.moreNotifications;
      if (!isEmpty(newNotification)) {
        state.firstPosted = last(action.payload.notifications).posted;
      }
      state.lastPosted = action.payload.postedMax;
      state.lastStatusChanged = action.payload.statusChangedMax;
      state.totalNotifications = action.payload.totalNotifications;
    });

    builder.addCase(fetchNotifications.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(markAsRead.fulfilled, (state, action) => {
      const id = parseInt(String(action.payload.id), 10);

      const list = state.list.map((notification) => {
        if (notification.id === id) {
          return { ...notification, status: 'read' };
        }
        return notification;
      });

      return {
        ...state,
        list,
      };
    });
  },
});

export const { resetState } = notificationSlice.actions;

export default notificationSlice;
