import Router from 'next/router';
import isEmpty from 'lodash/isEmpty';
import type { AxiosResponse } from 'axios';
import { MyPageContext } from 'kp';

import { EnhancedStore } from '@reduxjs/toolkit';

import { createBase64 } from '@lib/createBase64';
import redirectRequest from '@lib/redirectRequest';
import isServer from '@lib/ssrSetup/isServer';

import { LoginRoute } from '@server/routes';

import type { ThunkAPI } from '@store/configureStore';
import { Error, RequestStatus } from '@store/global/type';

const ignoredUrl = ['niri/suggested-search'];
const isIgnoredUrl = (url: string) => ignoredUrl.includes(url);

type HandleAccessLoginControlOnServerSideProps = {
  payload: any;
  store: EnhancedStore;
  ctx: MyPageContext;
};

type handleAccessLoginControlProps = {
  thunkAPI: ThunkAPI;
  url: string;
  response: AxiosResponse;
};

const getCommonValues = (thunkAPI: ThunkAPI, error: Error) => {
  const { getState } = thunkAPI;
  const { pageUrl = '' } = getState().meta;
  const returnUrl = createBase64(pageUrl);
  const accessDenied = 'yes';
  const redirectLoginRoute = LoginRoute.generateUrl(
    {},
    { returnUrl, accessDenied }
  );
  const errorCode = error?.error_code ?? '';
  const hasRedirect = errorCode === 'access_control_login_required';

  return { redirectLoginRoute, hasRedirect };
};

export const handleAccessLoginControl = async ({
  thunkAPI,
  url,
  response,
}: handleAccessLoginControlProps) => {
  if (isServer || isIgnoredUrl(url)) return;

  const { redirectLoginRoute, hasRedirect } = getCommonValues(
    thunkAPI,
    response?.data?.errors?.[0] ?? {}
  );

  if (hasRedirect) {
    Router.push(redirectLoginRoute);
  }
};

export const handleAccessLoginControlOnServerSide = async ({
  payload,
  store,
  ctx,
}: HandleAccessLoginControlOnServerSideProps) => {
  const { asPath } = ctx;
  const { meta: { requestStatus = '' } = {}, payload: { errors = [] } = {} } =
    payload;
  const hasAccessLoginControl =
    isEmpty(errors) ||
    isIgnoredUrl(asPath) ||
    requestStatus !== RequestStatus.rejected;

  if (hasAccessLoginControl) return;

  const { redirectLoginRoute, hasRedirect } = getCommonValues(store, errors[0]);

  if (hasRedirect) {
    await redirectRequest({ ctx, url: redirectLoginRoute, status: 302 });
  }
};
