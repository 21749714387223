import Router from 'next/router';

import { Dispatch, ThunkDispatch } from '@reduxjs/toolkit';

import {
  LOGIN_REASON_INFO_LIST,
  LoginReasonInfoProps,
} from '@lib/constants/loginReason.constants';

import { AreYouHumanRoute } from '@server/routes';

import { setCaptchaSiteKey } from '@store/antiBot/slice';
import { Any, Error } from '@store/global/type';

type AdSaveAuthValidateProps = {
  dispatch: ThunkDispatch<unknown, undefined, Any> & Dispatch<Any>;
  setLoginReasonInfo: (reason: LoginReasonInfoProps) => Any;
  errors: Error[];
  captchaSiteKey: string;
};

export const asyncAdSaveAuthValidate = ({
  dispatch,
  setLoginReasonInfo,
  errors,
  captchaSiteKey,
}: AdSaveAuthValidateProps) => {
  errors.map((e) => {
    if (e.error_code === 'areYouHuman') {
      dispatch(setCaptchaSiteKey(captchaSiteKey));
      if (e.error_description === 'hard')
        Router.push(AreYouHumanRoute.generateUrl());
    }

    if (e.error_code === 'not_logged_in') {
      dispatch(setLoginReasonInfo(LOGIN_REASON_INFO_LIST.newAd));
    }
    return null;
  });
};
