/* eslint-disable import/no-cycle */
import Router from 'next/router';
import { HYDRATE } from 'next-redux-wrapper';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { AD_SAVE_ROUTES } from '@lib/constants/adSave.constants';

import { routeChangeComplete } from '@hooks/useResetState';

import { HydrateAction } from '@store/global/type';
import {
  activatePromo,
  fetchPrices,
  updatePromoUrl,
} from '@store/promotion/thunk';
import { PromotionInitialState } from '@store/promotion/type';

const initialState: PromotionInitialState = {
  prices: {
    top7Day: {},
    topSearch7Day: {},
    priority3Day: {},
    video30Day: {},
    link30Day: {},
    highlight30Day: {},
    renew: {},
    adcreate: {},
  } as PromotionInitialState['prices'],
  isLoading: true,
  isActivePromoLoading: false,
  websiteError: false,
  videoUrlError: false,
  serviceTopFull: false,
  isUpdateUrlSuccess: false,
  isDraftAd: false,
  adCreateInfo: {
    activateLaterInfo: '',
    priceInfo: '',
    optionsNotSelectedError: '',
  },
  adCreateServiceActive: false,
};

const promotionSlice = createSlice({
  name: 'promotion',
  initialState,

  reducers: {
    resetErrors(state) {
      state.websiteError = false;
      state.videoUrlError = false;
      state.serviceTopFull = false;
    },
    resetUpdateUrlSuccessMessage(state) {
      state.isUpdateUrlSuccess = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'promotion'>) => {
        if (!isEqual(state, action.payload[promotionSlice.name])) {
          Object.assign(state, action.payload.promotion);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      const canResetState = !AD_SAVE_ROUTES.some((route) =>
        Router.asPath.includes(route)
      );
      if (canResetState) {
        Object.assign(state, initialState);
      }
    });

    builder.addCase(fetchPrices.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchPrices.fulfilled, (state, action) => {
      state.prices = action.payload;
      const {
        isDraftAd,
        adCreateInfo = {
          activateLaterInfo: '',
          priceInfo: '',
          optionsNotSelectedError: '',
        },
        adCreateServiceActive,
      } = action.payload;
      state.adCreateInfo = adCreateInfo;
      state.isDraftAd = isDraftAd;
      state.isLoading = false;
      state.adCreateServiceActive = adCreateServiceActive;
    });

    builder.addCase(activatePromo.pending, (state) => {
      state.isActivePromoLoading = true;
    });

    builder.addCase(activatePromo.fulfilled, (state) => {
      state.websiteError = false;
      state.videoUrlError = false;
      state.serviceTopFull = false;
    });

    builder.addCase(activatePromo.rejected, (state, action) => {
      state.websiteError = !isEmpty(
        action.payload?.filter((error) => error?.error_code.includes('website'))
      );
      state.videoUrlError = !isEmpty(
        action.payload?.filter((error) => error?.error_code.includes('video'))
      );
      state.serviceTopFull = !isEmpty(
        action.payload?.filter((error) =>
          error?.error_code.includes('service_top_full')
        )
      );
      state.isActivePromoLoading = false;
    });

    builder.addCase(updatePromoUrl.fulfilled, (state) => {
      state.websiteError = false;
      state.videoUrlError = false;
      state.isUpdateUrlSuccess = true;
    });

    builder.addCase(updatePromoUrl.rejected, (state, action) => {
      state.websiteError = !isEmpty(
        action.payload.filter((error) => error.error_code?.includes('website'))
      );
      state.videoUrlError = !isEmpty(
        action.payload.filter((error) => error.error_code?.includes('video'))
      );
    });
  },
});
export const { resetErrors, resetUpdateUrlSuccessMessage } =
  promotionSlice.actions;
export default promotionSlice;
