/* eslint-disable array-callback-return */
import Router from 'next/router';
import isEmpty from 'lodash/isEmpty';

import { Dispatch, ThunkDispatch } from '@reduxjs/toolkit';

import { CookiesAPI } from '@lib/common/cookies';

import { MyKPAccountRoute } from '@server/routes';

import { Any, Error } from '@store/global/type';
import { fetchUserProfile } from '@store/meProfile/thunk';
import { showToast, toggleModal } from '@store/modal/slice';

export const onSuccessSetDeliveryMethod = async (
  dispatch: ThunkDispatch<unknown, undefined, Any> & Dispatch<Any>,
  isMobile: boolean
) => {
  if (isMobile) {
    dispatch(
      showToast({
        content:
          'Uspešno je započeto dodavanje izabranog načina isporuke u svim Vašim oglasima.',
        description:
          'U slučaju velikog broja oglasa ovaj proces može potrajati i do 10 minuta.',
        isToast: true,
        duration: '5000',
      })
    );

    Router.push(MyKPAccountRoute.generateUrl());
  }

  if (!isMobile) {
    Router.push(
      MyKPAccountRoute.generateUrl({}, { infoBox: 'successSetDeliveryMethod' })
    );
  }

  CookiesAPI.set('accountDeliveryMethodLabel', '1');
  await dispatch(fetchUserProfile());
  await dispatch(toggleModal(null));
};

export const onFailureSetDeliveryMethod = (
  dispatch: ThunkDispatch<unknown, undefined, Any> & Dispatch<Any>,
  errors: Error[]
) => {
  if (!isEmpty(errors)) {
    dispatch(toggleModal(null));
  }
};
