/* eslint-disable import/no-cycle */
import Router from 'next/router';
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { KEY_STATS } from '@lib/constants/meStats.constants';
import { formatNumber } from '@lib/localization/formatNumber';
import { formatStringToNumber } from '@lib/localization/formatStringToNumber';

import { MyKPAdsRoute } from '@server/routes';

import { saveAd } from '@store/adSave/thunk';
import { fetchFeedbackThread } from '@store/feedback/thunk';
import { HydrateAction } from '@store/global/type';
import { fetchUserAds } from '@store/meAds/thunk';
import { editUserProfile } from '@store/meProfile/thunk';
import { fetchInbox } from '@store/messageInbox/thunk';
import { fetchThread } from '@store/messageThread/thunk';
import {
  changeUserStats,
  fetchPrepaid,
  fetchUnread,
  fetchUserGroupedInfo,
  fetchUserStats,
  setSeenWelcomeScreen,
} from '@store/meStats/thunk';
import { fetchNotifications } from '@store/notification/thunk';
import { activatePromo } from '@store/promotion/thunk';

import { MeStatsInitialState } from './type';

const initialState: MeStatsInitialState = {
  prepaid: null,
  unread: {
    messages: 0,
    notifications: 0,
  },
  isLoading: false,
  isLoaded: {
    prepaid: false,
    unread: false,
  },

  stats: {
    userId: null,
    totalAds: 0,
    totalReviews: 0,
    reviewsNegative: '0',
    reviewsPositive: '0',
    totalFollowingAds: 0,
    isSeenWelcomeScreenMegatron: true,
    email: '',
    userAdsUrl: '',
    kpIzlogUrl: '',
    isShowAdsLink: true,
    isKpRenewerExpireSoonVisible: false,
    isKpRenewerExpiredVisible: false,
    name: '',
    isUserProfessionalSeller: false,
    isPrepaidBonusAvailable: false,
    userType: '',
    userGroup: '',
    userLocation: '',
    userCreated: '',
    isFirstAd: false,
    isFirstCredit: false,
  },
  userAdsCount: {
    active: '...',
    deleted: '...',
    parked: '...',
    draft: '...',
  },
  isUserInLockedPeriod: false,
  courierOrder: {
    courierId: 1,
  },
  activateAdsInfo: {
    activationInfoText: '',
    activationPrice: 0,
  },
};

const meStatsSlice = createSlice({
  name: 'meStats',
  initialState,

  reducers: {
    resetMeStats(state) {
      Object.assign(state, initialState);
    },
    decreaseDraftAdsActivationPrice(state, action) {
      state.activateAdsInfo.activationPrice -= action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'meStats'>) => {
        if (!isEqual(state, action.payload[meStatsSlice.name])) {
          Object.assign(state, action.payload.meStats);
        }
      }
    );

    builder.addCase(fetchPrepaid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPrepaid.fulfilled, (state, action) => {
      state.prepaid = action.payload;
      state.isLoading = false;
      state.isLoaded.prepaid = true;
    });

    builder.addCase(fetchUnread.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUnread.fulfilled, (state, action) => {
      if (!isEqual(state.unread, action.payload)) {
        state.unread = action.payload;
      }
      state.isLoading = false;
      state.isLoaded.unread = true;
    });

    builder.addCase(fetchUserStats.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUserStats.fulfilled, (state, action) => {
      if (!isEqual(state.stats, action.payload)) {
        state.stats = action.payload;
      }
      state.isLoading = false;
    });

    builder.addCase(setSeenWelcomeScreen.fulfilled, (state, action) => {
      state.stats.isSeenWelcomeScreenMegatron = action.payload;
    });

    builder.addCase(editUserProfile.fulfilled, (state, action) => {
      const { name = '' } = action.meta.arg;
      state.stats = {
        ...state.stats,
        isShowAdsLink: action.meta.arg.showAdsLink === 'yes',
        ...(name && { name }),
      };
    });

    builder.addCase(fetchUserAds.fulfilled, (state, action) => {
      const {
        userAdsCount = initialState.userAdsCount,
        activateAdsInfo = initialState.activateAdsInfo,
      } = action.payload;
      state.stats.totalAds = formatNumber(userAdsCount.active as number);
      state.userAdsCount = userAdsCount;
      state.activateAdsInfo = activateAdsInfo;
    });

    builder.addCase(changeUserStats, (state, action) => {
      const { key, status, activationPrice = 0 } = action.payload;
      if (key === KEY_STATS.UNFOLLOW_AD) {
        const totalFollowingAds =
          formatStringToNumber(state.stats.totalFollowingAds) - 1;
        state.stats.totalFollowingAds = formatNumber(totalFollowingAds);
      }
      if (key === KEY_STATS.FOLLOW_AD) {
        const totalFollowingAds =
          formatStringToNumber(state.stats.totalFollowingAds) + 1;
        state.stats.totalFollowingAds = formatNumber(totalFollowingAds);
      }
      if (key === KEY_STATS.DELETE_AD || key === KEY_STATS.DELETE_DRAFT_AD) {
        const totalAds = formatNumber(
          formatStringToNumber(state.stats.totalAds)
        );
        switch (status) {
          case 'normal':
            state.stats.totalAds = Number(totalAds) - 1;
            state.userAdsCount.active = Number(totalAds) - 1;
            break;
          case 'parked':
            state.userAdsCount.parked -= 1;
            break;
          case 'draft':
            state.userAdsCount.draft -= 1;
            state.activateAdsInfo.activationPrice -= activationPrice;
            break;
          case 'deleted':
            state.userAdsCount.deleted -= 1;
            break;
          default:
            break;
        }
      }
      const shouldReturnOnMyAdsPage =
        key === KEY_STATS.DELETE_DRAFT_AD && state.userAdsCount.draft === 0;
      if (shouldReturnOnMyAdsPage) {
        Router.push(MyKPAdsRoute.generateUrl());
      }
      if (key === KEY_STATS.PARKED_AD) {
        const totalAds = formatNumber(
          formatStringToNumber(state.stats.totalAds) - 1
        );
        state.stats.totalAds = totalAds;
        state.userAdsCount.active = totalAds;
        state.userAdsCount.parked += 1;
      }
    });

    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      if (action?.payload?.pendingNotifications >= 0) {
        state.unread.notifications = action.payload.pendingNotifications;
      }
    });

    builder.addCase(fetchUserGroupedInfo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUserGroupedInfo.fulfilled, (state, action) => {
      const {
        unread = initialState.unread,
        stats = initialState.stats,
        prepaidBalance = initialState.prepaid,
        isAdForbiddenBecausePrepaidDebt = initialState.isUserInLockedPeriod,
        courierOrder = initialState.courierOrder,
      } = action.payload;
      state.prepaid = prepaidBalance;
      state.isUserInLockedPeriod = isAdForbiddenBecausePrepaidDebt;
      state.courierOrder = courierOrder;
      if (!isEqual(state.unread, unread)) {
        state.unread = unread;
      }
      if (!isEqual(state.stats, stats)) {
        state.stats = stats;
      }

      state.isLoading = false;
      state.isLoaded.prepaid = true;
      state.isLoaded.unread = true;
    });

    builder.addCase(fetchInbox.fulfilled, (state, action) => {
      if (action?.payload?.pendingMessages >= 0) {
        state.unread.messages = action.payload.pendingMessages;
      }
    });

    builder.addCase(fetchThread.fulfilled, (state, action) => {
      if (action?.payload?.pendingMsgs?.length > 0) {
        state.unread.messages -= 1;
      }
      state.courierOrder.courierId = action?.payload?.courierId;
    });

    builder.addCase(fetchFeedbackThread.fulfilled, (state, action) => {
      if (action?.payload?.pendingMsgs) {
        state.unread.messages -= 1;
      }
    });

    builder.addCase(activatePromo.fulfilled, (state, { payload }) => {
      state.prepaid = payload?.prepaidBalance;
    });

    builder.addCase(saveAd.fulfilled, (state, { payload }) => {
      state.prepaid = payload?.prepaidBalance;
    });
  },
});

export const { resetMeStats, decreaseDraftAdsActivationPrice } =
  meStatsSlice.actions;

export default meStatsSlice;
