import { HYDRATE } from 'next-redux-wrapper';
import { Action } from 'redux';
import { RawAxiosRequestHeaders } from 'axios';

import { PayloadAction, SerializedError } from '@reduxjs/toolkit';

import type { ThunkAPI } from '@store/configureStore';

export type Any = any;

export enum RequestStatus {
  idle = 'idle',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
  pending = 'pending',
}

export type EmptyObject = {
  [key: string]: Any;
};

export type Error = {
  error_code?: string;
  error_description?: string;
};

export type RejectedError = Error[] & [];

export interface Response<ResultType = [], ErrorType = [] | EmptyObject> {
  errors: ErrorType;
  result?: [];
  results?: ResultType;
  success: boolean;
  info?: unknown;
  categories?: unknown;
  pages?: number;
}

type OptionalResponseHeader = {
  [key: string]: string;
};

export interface ApiResponse<ResultType = []> {
  data: Response<ResultType>;
  headers?: OptionalResponseHeader;
}

type OptionalBodyType<T> = T | undefined;
export interface RequestConfig<BodyType> {
  method: string;
  body?: OptionalBodyType<BodyType>;
  headers?: RawAxiosRequestHeaders;
  onUploadProgress?: (progressEvent: Any) => void;
  thunkAPI: ThunkAPI;
}

export interface RequestConfigOld<BodyType> {
  method: string;
  body?: OptionalBodyType<BodyType>;
  headers?: RawAxiosRequestHeaders;
  onUploadProgress?: (progressEvent: Any) => void;
}

export type SearchParamsObj =
  | string
  | string[][]
  | Record<string, string>
  | URLSearchParams;

export type PromiseActionType<
  ResultType = [],
  PayloadType = [],
  ErrorType = []
> =
  | PayloadAction<
      ResultType,
      string,
      {
        arg: PayloadType;
        requestId: string;
        requestStatus: 'fulfilled';
        rejectedWithValue?: boolean;
      },
      never
    >
  | PayloadAction<
      RejectedError | ErrorType,
      string,
      {
        arg: PayloadType;
        requestId: string;
        requestStatus: 'rejected';
        aborted: boolean;
        condition: boolean;
        rejectedWithValue?: boolean;
      },
      SerializedError
    >;

export interface HydrateAction<T, K extends string = string>
  extends Action<typeof HYDRATE> {
  payload: Record<K, T> & Record<string, unknown>;
}

export interface DynamicAction<T, Type extends string = string>
  extends Action<Type> {
  payload: T;
}
