import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { routeChangeComplete } from '@hooks/useResetState';

import { saveAd } from '@store/adSave/thunk';
import {
  fetchSuggestedGroups,
  fetchSuggestedSearch,
  setGroupFromSuggestions,
} from '@store/classifier/thunk';
import { HydrateAction } from '@store/global/type';

import { AllSuggestionType, ClassifierInitialState } from './type';

const initialState: ClassifierInitialState = {
  suggestions: [] as AllSuggestionType[],
  suggestionsSearch: [],
  isLoading: false,
  hoveredSuggestionIndex: null,
};

const classifierSlice = createSlice({
  name: 'classifier',
  initialState,

  reducers: {
    clearSuggestedGroups(state) {
      state.suggestions = [];
    },
    setHoveredIndex(state, action) {
      state.hoveredSuggestionIndex = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'classifier'>) => {
        if (!isEqual(state, action.payload[classifierSlice.name])) {
          Object.assign(state, action.payload.classifier);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      Object.assign(state, initialState);
    });

    builder.addCase(fetchSuggestedGroups.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSuggestedGroups.fulfilled, (state, action) => {
      state.suggestions = action.payload as AllSuggestionType[];
      state.isLoading = false;
    });

    builder.addCase(fetchSuggestedGroups.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(fetchSuggestedSearch.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSuggestedSearch.fulfilled, (state, action) => {
      state.suggestionsSearch = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchSuggestedSearch.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(setGroupFromSuggestions.fulfilled, (state) => {
      state.suggestions = [];
    });

    builder.addCase(setGroupFromSuggestions.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(saveAd.fulfilled, (state) => {
      state.suggestions = [];
    });
  },
});

export const { clearSuggestedGroups, setHoveredIndex } =
  classifierSlice.actions;

export default classifierSlice;
