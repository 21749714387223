/* eslint-disable camelcase */
import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { HydrateAction } from '@store/global/type';
import {
  CHANNEL,
  DEVICE_TYPE,
  MODE_TYPES,
  PAGE_TYPES,
  THEME_TYPES,
} from '@store/meta/utils';

import { MetaInitialState } from './type';

const initialState: MetaInitialState = {
  device: null,
  deviceType: DEVICE_TYPE.default,
  theme: THEME_TYPES.system,
  mode: MODE_TYPES.default,
  pageType: PAGE_TYPES.index,
  channel: CHANNEL.default,
  categoryId: 0,
  userAgent: '',
  isMaintenanceMode: false,
  isBlacklistMode: false,
  isIframe: false,
  activeTheme: THEME_TYPES.system,
  isDesktopRequested: false,
  screenWidth: null,
  softBlockError: {
    reason: '',
    description: '',
  },
  agreementRules: {
    agreementRulesError: '',
    agreementRulesTitle: '',
    agreementRulesDescription: '',
  },
  locals: {
    cookies: '',
    userAgent: '',
    ip: '',
    port: '',
    pid: '',
    hostname: '',
    session: '',
    nonce: '',
    asPath: '',
  },
  headers: {
    kpSession: '',
    kpMachineId: '',
  },
  pageUrl: '',
};

const metaSlice = createSlice({
  name: 'meta',
  initialState,

  reducers: {
    setDeviceType(state, action) {
      state.deviceType = DEVICE_TYPE[action.payload] ?? DEVICE_TYPE.default;
    },
    setDevice(state, action) {
      state.device = action.payload;
      const channel = CHANNEL[action.payload.platform.type];
      state.channel = channel || CHANNEL.default;
    },
    setMode(state, action) {
      state.mode = MODE_TYPES[action.payload.mode];
      state.categoryId = action.payload?.id ?? 0;
    },
    setPageType(state, action) {
      state.pageType = PAGE_TYPES[action.payload];
    },
    setMaintenanceMode(state, action) {
      state.isMaintenanceMode = action.payload;
    },
    setBlacklistMode(state, action) {
      state.isBlacklistMode = action.payload;
    },
    setIframeMode(state, action) {
      state.isIframe = action.payload;
    },
    setActiveTheme(state, action) {
      state.activeTheme = THEME_TYPES[action.payload];
    },
    setLocals(state, action) {
      state.locals = action.payload;
    },
    setIsDesktopRequested(state, action) {
      state.isDesktopRequested = action.payload;
    },
    setScreenWidth(state, action) {
      state.screenWidth = action.payload;
    },
    setKpSession(state, action) {
      state.headers.kpSession = action.payload;
    },
    setKpMachineId(state, action) {
      state.headers.kpMachineId = action.payload;
    },
    setSoftBlockError(state, action) {
      if (action?.payload?.error_code === 'softblock') {
        state.softBlockError.reason = action?.payload?.decline_reason;
        state.softBlockError.description = action?.payload?.decline_description;
      }
    },
    resetSoftBlockError(state) {
      state.softBlockError.reason = '';
      state.softBlockError.description = '';
    },
    setAgreementsRules(state, action) {
      const { agreement_rules_description, agreement_rules_title, error_code } =
        action.payload;
      state.agreementRules.agreementRulesDescription =
        agreement_rules_description;
      state.agreementRules.agreementRulesTitle = agreement_rules_title;
      state.agreementRules.agreementRulesError = error_code;
    },
    resetAgreementsRules(state) {
      state.agreementRules.agreementRulesDescription = '';
      state.agreementRules.agreementRulesTitle = '';
      state.agreementRules.agreementRulesError = '';
    },
    setPageUrl(state, action) {
      state.pageUrl = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'meta'>) => {
        if (!isEqual(state, action.payload[metaSlice.name])) {
          Object.assign(state, action.payload.meta);
        }
      }
    );
  },
});

export const {
  setDevice,
  setDeviceType,
  setMode,
  setPageType,
  setMaintenanceMode,
  setBlacklistMode,
  setIframeMode,
  setActiveTheme,
  setLocals,
  setIsDesktopRequested,
  setScreenWidth,
  setKpSession,
  setKpMachineId,
  setSoftBlockError,
  resetSoftBlockError,
  setAgreementsRules,
  resetAgreementsRules,
  setPageUrl,
} = metaSlice.actions;

export const metaSelector = (state) => state[metaSlice.name];

export default metaSlice;
