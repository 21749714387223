import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { normalize } from 'src/lib/normalize';

import {
  fetchCategories,
  fetchUserFavoriteCategories,
} from '@store/category/thunk';
import { fetchGeneralGroupedInfo } from '@store/generalStats/thunk';
import { HydrateAction } from '@store/global/type';
import { fetchUserAds } from '@store/meAds/thunk';

import { CategoryInitialState } from './type';

const initialState: CategoryInitialState = {
  categories: [],
  filteredCategories: [],
  isLoading: false,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'category'>) => {
        if (!isEqual(state, action.payload[categorySlice.name])) {
          Object.assign(state, action.payload.category);
        }
      }
    );

    builder.addCase(fetchGeneralGroupedInfo.fulfilled, (state, action) => {
      const { categories = initialState.categories } = action.payload;
      const nextCategories = normalize(categories, 'id');

      if (!isEqual(state.categories, nextCategories)) {
        state.categories = nextCategories;
      }
      state.isLoading = false;
    });

    builder.addCase(fetchCategories.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchUserAds.fulfilled, (state, action) => {
      const { filteredCategories = [] } = action.payload;
      state.filteredCategories = normalize(filteredCategories, 'id');
    });

    builder.addCase(fetchUserFavoriteCategories.fulfilled, (state, action) => {
      const { filteredCategories = [] } = action.payload;
      state.filteredCategories = normalize(filteredCategories, 'id');
    });
  },
});

export default categorySlice;
