import { HYDRATE } from 'next-redux-wrapper';
import unset from 'lodash/unset';
import without from 'lodash/without';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { extractByKey } from '@lib/extractByKey';
import { normalize } from '@lib/normalize';

import { routeChangeComplete } from '@hooks/useResetState';

import { HydrateAction } from '@store/global/type';

import { addNote, deleteFromAddressBook, fetchAddressBookList } from './thunk';
import { AddressBookInitialState } from './type';

const initialState: AddressBookInitialState = {
  pages: 0,
  isLoading: true,
  listIds: [],
  byId: {},
};

const addressBookSlice = createSlice({
  name: 'addressBook',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'addressBook'>) => {
        if (!isEqual(state, action.payload[addressBookSlice.name])) {
          Object.assign(state, action.payload.addressBook);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      Object.assign(state, initialState);
    });

    builder.addCase(fetchAddressBookList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchAddressBookList.fulfilled, (state, action) => {
      const { results, pages } = action.payload;

      state.isLoading = false;
      state.pages = pages;
      state.byId = normalize(results, 'contactId');
      state.listIds = extractByKey(results, 'contactId');
    });

    builder.addCase(deleteFromAddressBook.fulfilled, (state, action) => {
      const { contact_id: id } = action.meta.arg;
      const listIds = without(state.listIds, id);
      state.listIds = listIds;
      unset(state.listIds, id);
    });

    builder.addCase(addNote.fulfilled, (state, action) => {
      const { contact_id: id, note } = action.meta.arg;
      if (state?.byId?.[id]) {
        state.byId[id].contactNote = note;
      }
    });
  },
});

export default addressBookSlice;
