import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { fetchCarValue } from '@store/customValue/thunk';
import { HydrateAction } from '@store/global/type';

import { createCarValues } from './model';
import { CustomCarValues, CustomValueInitialState } from './type';

const initialState: CustomValueInitialState = {
  isLoading: false,
  carValue: {} as CustomCarValues,
};

const customValueSlice = createSlice({
  name: 'customValue',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'customValue'>) => {
        if (!isEqual(state, action.payload[customValueSlice.name])) {
          Object.assign(state, action.payload.customValue);
        }
      }
    );

    builder.addCase(fetchCarValue.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCarValue.fulfilled, (state, action) => {
      if (action?.payload) {
        state.carValue = createCarValues(action.payload);
      }
      state.isLoading = false;
    });
  },
});

export default customValueSlice;
