import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { normalize } from 'src/lib/normalize';

import { routeChangeStart } from '@hooks/useRouteChangeStart';

import { HydrateAction } from '@store/global/type';
import {
  fetchReviewForAd,
  fetchReviewList,
  fetchUserCheck,
  postReview,
} from '@store/review/thunk';

import { createReview } from './model';
import { ReviewInitialState } from './type';

const initialState: ReviewInitialState = {
  list: [],
  currentPage: 1,
  totalPages: 1,
  userCheck: null,
  userAds: [],
  thankYouNote: '',
  isReviewBoxOpen: false,
  isLoading: true,
  loading: 'idle',
};

const reviewSlice = createSlice({
  name: 'review',
  initialState,

  reducers: {
    fetchResponseMessage(state) {
      state.isLoading = true;
    },
    setUserCheck(state, action) {
      const { success, results } = action.payload;
      state.userCheck = success;
      state.userAds = results ? normalize(results.ads, 'id') : [];
      state.isLoading = false;
    },
    setReviewBoxOpen(state, action) {
      state.isReviewBoxOpen = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'review'>) => {
        if (!isEqual(state, action.payload[reviewSlice.name])) {
          Object.assign(state, action.payload.review);
        }
      }
    );

    builder.addCase(routeChangeStart, (state) => {
      Object.assign(state, initialState);
    });

    builder.addCase(fetchReviewForAd.pending, (state) => {
      state.isLoading = true;
      state.loading = 'pending';
    });

    builder.addCase(fetchReviewForAd.fulfilled, (state, action) => {
      state.list = action.payload.map((review) => createReview(review));
      state.isLoading = false;
      state.loading = 'fulfilled';
    });

    builder.addCase(fetchReviewList.pending, (state) => {
      state.isLoading = true;
      state.loading = 'pending';
    });

    builder.addCase(fetchReviewList.fulfilled, (state, action) => {
      const { reviews = [], page = 1, pages = 1 } = action.payload;

      state.list = reviews.map((review) => createReview(review));
      state.currentPage = page;
      state.totalPages = pages;
      state.isLoading = false;
      state.loading = 'fulfilled';
    });

    builder.addCase(fetchUserCheck.fulfilled, (state, action) => {
      const { success, results } = action.payload;
      state.userCheck = success;
      state.userAds = results ? normalize(results.ads, 'id') : [];
    });

    builder.addCase(postReview.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postReview.fulfilled, (state, action) => {
      state.thankYouNote = action.payload?.message ?? '';
      state.isLoading = false;
    });
  },
});

export const { fetchResponseMessage, setReviewBoxOpen, setUserCheck } =
  reviewSlice.actions;

export default reviewSlice;
