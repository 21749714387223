import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { submitFraudReport } from '@store/fraud/thunk';
import { HydrateAction } from '@store/global/type';

import { FraudInitialState } from './type';

const initialState: FraudInitialState = {
  isLoading: false,
  errors: [],
};

const fraudSlice = createSlice({
  name: 'fraud',
  initialState,

  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'fraud'>) => {
        if (!isEqual(state, action.payload[fraudSlice.name])) {
          Object.assign(state, action.payload.fraud);
        }
      }
    );

    builder.addCase(submitFraudReport.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(submitFraudReport.fulfilled, (state) => {
      state.isLoading = false;
      state.errors = [];
    });

    builder.addCase(submitFraudReport.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    });
  },
});

export const { reset } = fraudSlice.actions;

export default fraudSlice;
