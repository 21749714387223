import { HYDRATE } from 'next-redux-wrapper';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { normalize } from 'src/lib/normalize';

import { fetchGeneralGroupedInfo } from '@store/generalStats/thunk';
import { HydrateAction } from '@store/global/type';

import { CurrencyConverterInitialState, RateObject } from './type';

const initialState: CurrencyConverterInitialState = {
  rates: {} as RateObject,
  isLoading: true,
};

const currencyConverterSlice = createSlice({
  name: 'currencyConverter',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (
        state,
        action: HydrateAction<typeof initialState, 'currencyConverter'>
      ) => {
        if (!isEqual(state, action.payload[currencyConverterSlice.name])) {
          Object.assign(state, action.payload.currencyConverter);
        }
      }
    );

    builder.addCase(fetchGeneralGroupedInfo.fulfilled, (state, action) => {
      const { nbsRates = initialState.rates } = action.payload;
      state.rates = normalize(nbsRates, 'id');
      state.isLoading = false;
    });
  },
});

export default currencyConverterSlice;
