import Router from 'next/router';
import { HYDRATE } from 'next-redux-wrapper';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'react-fast-compare';

import { createSlice } from '@reduxjs/toolkit';

import { routeChangeComplete } from '@hooks/useResetState';

import {
  exportActiveUserAds,
  fetchAd,
  fetchAdsPosition,
  fetchAdTraverse,
  followAd,
  parkAd,
  parkAllAds,
  setAllAdsDeliveryMethods,
  unfollowAd,
  unparkAd,
  unparkAllAds,
} from '@store/ad/thunk';
import {
  addToAddressBook,
  deleteFromAddressBook,
} from '@store/addressBook/thunk';
import { fetchUserOwnedAd, saveAd } from '@store/adSave/thunk';
import { HydrateAction } from '@store/global/type';
import { ignoreUserAds, unIgnoreUserAds } from '@store/meAds/thunk';
import { updatePromoUrl } from '@store/promotion/thunk';

import { AdInitialState } from './type';

const initialState: AdInitialState = {
  isLoading: false,
  traverse: {
    previous: {},
    next: {},
    isAdTraverseFetched: false,
    isNextLoading: false,
    isPreviousLoading: false,
  },
  byId: {},
  deletedAdInfo: {},
  position: {
    byId: {},
    isLoading: false,
  },
  unparkAdInfo: {
    adId: 0,
    groupPage: 0,
    adName: '',
  },
  isLoadingParkAllAds: false,
  loading: 'idle',
  isNewAd: false,
  isLoadingSetAllAdsDeliveryMethod: false,
  isDisabledSetAllAdsDeliveryMethod: false,
  draftAdInterceptorText: {},
};

const adSlice = createSlice({
  name: 'ad',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<typeof initialState, 'ad'>) => {
        if (!isEqual(state, action.payload[adSlice.name])) {
          Object.assign(state, action.payload.ad);
        }
      }
    );

    builder.addCase(routeChangeComplete, (state) => {
      state.isDisabledSetAllAdsDeliveryMethod =
        initialState.isDisabledSetAllAdsDeliveryMethod;
    });

    builder.addCase(ignoreUserAds.fulfilled, (state, action) => {
      const adId = action.meta.arg;
      if (state.byId?.[adId]) {
        state.byId[adId].adUserIsIgnored = true;
      }
    });

    builder.addCase(unIgnoreUserAds.fulfilled, (state, action) => {
      const adId = action.meta.arg;
      if (state.byId?.[adId]) {
        state.byId[adId].adUserIsIgnored = false;
      }
    });

    builder.addCase(saveAd.fulfilled, (state, action) => {
      const { newAd = false, draftedAdInfoText = {} } = action.payload;
      state.isNewAd = newAd;
      state.draftAdInterceptorText = draftedAdInfoText;
    });

    builder.addCase(fetchAd.pending, (state, action) => {
      const { adId } = action.meta.arg;
      const activeAd = state.byId[adId];

      if (!isEmpty(activeAd)) {
        state.isLoading = false;
      } else {
        state.isLoading = true;
      }
      state.deletedAdInfo = {};
    });

    builder.addCase(fetchAd.fulfilled, (state, action) => {
      const { adId } = action.meta.arg;
      state.byId[adId] = action?.payload ? action?.payload : {};
      state.isLoading = false;
      state.traverse = initialState.traverse;
      state.isNewAd = false;
    });

    builder.addCase(fetchAd.rejected, (state, action) => {
      state.deletedAdInfo = action?.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchAdTraverse.pending, (state, action) => {
      const { position = '' } = action.meta.arg;
      state.traverse.isAdTraverseFetched = false;
      if (position === 'next') {
        state.traverse.isNextLoading = true;
      }
      if (position === 'previous') {
        state.traverse.isPreviousLoading = true;
      }
    });

    builder.addCase(fetchAdTraverse.fulfilled, (state, action) => {
      const { payload } = action;
      state.traverse.isAdTraverseFetched = true;
      state.traverse.isNextLoading = false;
      state.traverse.isPreviousLoading = false;

      if (payload.previous) {
        state.traverse.previous = payload.previous;
      } else {
        state.traverse.previous = {};
      }
      if (payload.next) {
        state.traverse.next = payload.next;
      } else {
        state.traverse.next = {};
      }
    });

    builder.addCase(followAd.fulfilled, (state, action) => {
      const { adId } = action.meta.arg;
      if (state.byId[adId]) {
        state.byId[adId].isFollowing = true;
        state.byId[adId].favoriteCount += 1;
      }
    });

    builder.addCase(unfollowAd.fulfilled, (state, action) => {
      const { adId } = action.meta.arg;
      if (state.byId[adId]) {
        state.byId[adId].isFollowing = false;
        state.byId[adId].favoriteCount -= 1;
      }
    });

    builder.addCase(exportActiveUserAds.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(exportActiveUserAds.fulfilled, (state) => {
      state.isLoading = false;
    });

    builder.addCase(exportActiveUserAds.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(parkAd.pending, (state, action) => {
      const { adId = '' } = action.meta.arg;
      state.loading = `parkAd${adId}`;
    });

    builder.addCase(parkAd.fulfilled, (state, action) => {
      const { adId } = action.meta.arg;
      if (state.byId[adId]) {
        state.byId[adId].isParked = true;
      }
      state.loading = 'idle';
    });

    builder.addCase(unparkAd.pending, (state, action) => {
      const { adId = '' } = action.meta.arg;
      state.loading = `parkAd${adId}`;
    });

    builder.addCase(unparkAd.fulfilled, (state, action) => {
      const { adId } = action.meta.arg;
      if (state.byId[adId]) {
        state.byId[adId].isParked = false;
      }
      state.unparkAdInfo = action.payload;
      state.loading = 'idle';
    });

    builder.addCase(fetchAdsPosition.pending, (state) => {
      state.position.isLoading = true;
    });

    builder.addCase(fetchAdsPosition.fulfilled, (state, action) => {
      state.position.byId = action.payload;
      state.position.isLoading = false;
    });

    builder.addCase(fetchUserOwnedAd.fulfilled, (state, action) => {
      const { adId } = action.payload;
      state.byId[adId] = action.payload;
    });

    builder.addCase(parkAllAds.pending, (state) => {
      state.isLoadingParkAllAds = true;
    });

    builder.addCase(unparkAllAds.pending, (state) => {
      state.isLoadingParkAllAds = true;
    });

    builder.addCase(parkAllAds.rejected, (state) => {
      state.isLoadingParkAllAds = false;
    });

    builder.addCase(unparkAllAds.rejected, (state) => {
      state.isLoadingParkAllAds = false;
    });

    builder.addCase(parkAllAds.fulfilled, (state) => {
      state.isLoadingParkAllAds = false;
    });

    builder.addCase(unparkAllAds.fulfilled, (state) => {
      state.isLoadingParkAllAds = false;
    });

    builder.addCase(deleteFromAddressBook.fulfilled, (state) => {
      const {
        query: { adId = '' },
      } = Router.router;
      if (state.byId[adId as string]) {
        state.byId[adId as string].user.isUserInAddressBook = false;
      }
    });

    builder.addCase(addToAddressBook.fulfilled, (state) => {
      const {
        query: { adId = '' },
      } = Router.router;
      if (state.byId[adId as string]?.user) {
        state.byId[adId as string].user.isUserInAddressBook = true;
      }
    });

    builder.addCase(updatePromoUrl.fulfilled, (state, action) => {
      const { website, videoUrl, adId } = action.meta.arg;
      if (state.byId[adId as string] && !isEmpty(website)) {
        state.byId[adId as string].website = website;
      }
      if (state.byId[adId as string] && !isEmpty(videoUrl)) {
        state.byId[adId as string].video = videoUrl;
      }
    });

    builder.addCase(setAllAdsDeliveryMethods.pending, (state) => {
      state.isLoadingSetAllAdsDeliveryMethod = true;
    });

    builder.addCase(setAllAdsDeliveryMethods.fulfilled, (state) => {
      state.isLoadingSetAllAdsDeliveryMethod = false;
      state.isDisabledSetAllAdsDeliveryMethod = true;
    });

    builder.addCase(setAllAdsDeliveryMethods.rejected, (state) => {
      state.isLoadingSetAllAdsDeliveryMethod = false;
      state.isDisabledSetAllAdsDeliveryMethod = true;
    });
  },
});

export default adSlice;
