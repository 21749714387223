import { Option } from '@type/common/selectOption';

import { EmptyObject, RequestStatus, Response } from '@store/global/type';

export enum AddressStepMobile {
  address = 0,
  country = 1,
  street = 2,
  town = 3,
}

export enum AddressSource {
  folder = 'folder',
  interceptor = 'interceptor',
  account = 'account',
  adView = 'adView',
  message = 'message',
  courier = 'courier',
  initialProfileSetup = 'initialProfileSetup',
  loginProfileSetup = 'loginProfileSetup',
}

export type Address = {
  addressId?: number;
  userId?: number;
  name?: string;
  companyName?: string;
  country?: string;
  city?: string;
  postalCode?: string;
  streetName?: string;
  streetNumber?: string;
  apartmentNumber?: string;
  phone?: string;
  posted?: string;
  isPrimary?: boolean;
  townId?: number;
  messageId?: number;
  isDexpressDeliveryAvailable?: boolean;
  id?: number;
};

type AddressUserInfo = {
  name: string;
  country: string;
  city: string;
  phone: string;
};

export type AddressCountriesInfo = {
  id: string;
  name: string;
};

export type Town = {
  townId: number;
  townName: string;
  postalCode: string;
};

export type Street = {
  streetId: number;
  streetName: string;
} & Town;

export type StreetOption = Option & Street;

export type TownOption = Option & Town;

export type FetchTownsPayload = {
  keywords: string;
  country: string;
  streetName: string;
};

export type FetchTownsResponse = Response<TownOption[]>;

export type FetchStreetsPayload = {
  keywords: string;
  country: string;
};

export type FetchStreetsResponse = Response<StreetOption[]>;

export type AddressInfoInitialState = {
  list: Address[];
  userInfo: AddressUserInfo | EmptyObject;
  countries: AddressCountriesInfo[];
  isLimitReached: boolean;
  maxAddressNumPerUser: number;
  notificationTitle: string;
  notificationDescription: string;
  matchedAddress: Address;
  selectedAddress: Address;
  loading: RequestStatus;
  streets: StreetOption[];
  towns: TownOption[];
  isLoadingStreets: boolean;
  isLoadingTowns: boolean;
  lastStreetsPayload: FetchStreetsPayload | null;
  lastTownsPayload: FetchTownsPayload | null;
};

export type SaveAddressPayload = {
  adId?: string | number;
  receiverId?: string | number;
  threadId?: string | number;
  phone?: string;
  country?: string;
  source?: AddressSource;
  addressId?: number;
  isPrimary?: boolean;
};

export type FetchAddressPayload = {
  adId?: string | number;
  skipToggleModal?: boolean;
  source?: AddressSource;
};

export type DeleteAddressPayload = {
  id: string | number;
  adId?: string | number;
};

export type SaveAddress = {
  addressId: number;
};

export type SaveAddressResponse = Response & SaveAddress;
export type FetchAddressResponse = Response<AddressInfoInitialState>;
